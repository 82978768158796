import React, { useState } from 'react'
import {
  View, Picker, StyleSheet, SafeAreaView,
  Platform,
  Alert, Modal, Pressable
} from "react-native";
import {
  Button, IconButton, Text, TextInput, List, Surface, Snackbar,
  Paragraph, Dialog, Portal, Provider
} from 'react-native-paper';

import DropDownPicker from 'react-native-dropdown-picker';
import DateTimePicker from '@react-native-community/datetimepicker';
import { TimePicker } from 'react-native-simple-time-picker';

import ioHelper from "../Helpers/ioHelper";
import { ScrollView } from 'react-native-gesture-handler';
import { keys } from 'lodash';

const { sendDataToFleetController, getScheduleData, createScheduleData, editScheduleData } = require('../Helpers/apiHelper')
const cronTime = require('cron-time-generator');



var cronParser = require('cron-parser');

function cronStr2DateStr(cronStr) {
  try {
    var interval = cronParser.parseExpression(cronStr);
    return interval.next().toString()
  } catch (e) {
    return "Invalid cron string";
  }
}




function SettingScreen({ client, userPassword }) {

  // COMPONENTS
  const PickerWeb = ({ type, selected, setSelected, contents }) => {
    return (
      <>

        <Text>
          Select a {type}
        </Text>
        <Picker
          selectedValue={selected}
          style={[styles.dropdownPickerWeb, styles.bodyText]}
          itemStyle={[styles.dropdownPickerWeb, styles.bodyText]}
          onValueChange={(itemValue) =>
            setSelected(itemValue)
          }>
          {contents.map((x, i) => (
            <Picker.Item key={`${i}`} label={x.label} value={x.value} />
          ))}
        </Picker>

      </>

    )
  }

  const PickerAndroid = ({
    open, value, placeholder, items, setOpen, setValue, setItems,
  }) => {
    return (
      <DropDownPicker
        itemSeparator={true}
        listMode="MODAL"
        bottomOffset={300}
        open={open}
        value={value}
        placeholder={placeholder}
        items={items}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        style={styles.dropdownPicker}
        textStyle={
          styles.bodyText
        }
        labelStyle={
          styles.bodyText
        }
        modalContentContainerStyle={{
          backgroundColor: "#777",
        }}
        itemSeparatorStyle={{
          color: "#fff",
          width: "100%",
        }}
        labelProps={{
          value: "VALUE",
        }}
      // theme="DARK"
      />
    )
  }


  // STATES



  // Pickers
  const [openDepartmentsDropdown, setOpenDepartmentsDropdown] = useState(false);  // For the Departments dropdown list 
  const [departmentValue, setDepartmentValue] = useState(null);
  const [departmentDropDownListItems, setDepartmentDropDownListItems] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [userAccessLevel, setUserAccessLevel] = useState('');

  // Get the list of departments from a user's clientId
  React.useEffect(() => {


    async function getDepartmentsList() {
      const clientId = await ioHelper.retrieveData("client_id");
      const postData = {
        command: "getDepartments",
        data: {
          clientId: clientId,
        },
      };

      const { data } = await sendDataToFleetController('robots', postData);
      if (data?.departments) {
        setDepartmentDropDownListItems(data.departments.map(x => {
          return { label: x, value: x }
        }))
      }
    }

    async function getUserAccessLevel() {
      const a = await ioHelper.retrieveData("access_level")
      setUserAccessLevel(a);
    }

    getDepartmentsList();
    getUserAccessLevel();

  }, [])


  // The dialog popup 
  const [dialogContent, setDialogContent] = React.useState({
    title: '',
    data: {},
  });
  const [visible, setVisible] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');

  // Update dialog content
  React.useEffect(() => {

    setDialogContent({
      ...dialogContent,
      data: departmentValue || selectedDepartment,
    })
  }, [departmentValue, selectedDepartment])


  // Get user data from fleet manager
  const [userData, setUserData] = React.useState({
    clientName: '',
    pin: '',
    employeeId: '',
    clientId: '',
  })
  React.useEffect(() => {

    async function getUserData() {
      const alias = await ioHelper.retrieveData("user_name");
      const password = userPassword;
      const postData = {
        command: "getUserData",
        data: {
          alias: alias,
          password: password
        },
      };

      let { data } = await sendDataToFleetController('clients', postData);
      const cName = await getClientName();
      if (data) {
        if (cName) data.clientName = cName;
        setUserData({ ...userData, ...data });
      }


      async function getClientName() {
        const clientId = await ioHelper.retrieveData("client_id");
        const postData = {
          command: "getClientName",
          data: {
            clientId: clientId
          },
        };

        const { data } = await sendDataToFleetController('robots', postData);

        if (data?.clientName) {
          return data.clientName;
        } else {
          return "Not found";
        }
      }

    }

    getUserData();

  }, [visible])



  // Get the device type
  const [deviceType, setDeviceType] = useState(null);
  React.useEffect(() => {


    async function retrieveDType() {
      const dType = await ioHelper.retrieveData("device_type");
      setDeviceType(dType);
      console.log("DEVICETYPE:", dType)
    }

    retrieveDType();

  }, [deviceType, setDeviceType]);



  // METHODS
  // Dialog action on edit pressed  
  const hideDialog = () => {
    setVisible(false);
    setErrorText('')
  }
  const showDialog = () => setVisible(true);

  async function displayEditDialog(field, data) {
    console.log("EDIT", field, data[field]);

    setDialogContent({
      ...dialogContent,
      title: `Edit ${field}`,
      data: data[field],
    })

    showDialog()
  }

  // Edit dialog done clicked
  function submitEditData() {

    let dialog = dialogContent.title.split(" ")[1];

    switch (dialog) {
      case "charge":
        updateScheduleTimes();
        break;
      case "statistics":
        updateScheduleTimes();
        break;
      case "deliveries":
        updateScheduleTimes();
        break;
      case "department":
        handleClientInfo(dialog);
        break;
      case "pin":
        handleClientInfo(dialog);
        break;
      default:
        break;
    }

    hideDialog();
    handleReset();

    async function updateScheduleTimes() {

      console.log({ scheduleIds })
      console.log({ dates })

      const clientId = await ioHelper.retrieveData("client_id");

      Object.keys(scheduleIds).forEach(async (key, index) => {
        if (scheduleIds[key] == null) {
          if (dates[key]) {
            // Create 
            const convertedTime = dateString2Cron(dates[key])
            console.log("Create", scheduleIds[key], dates[key], key);
            const body = {
              "to": [
                { "type": decideType(key), "id": decideId(key) }
              ],
              "cron": convertedTime ? convertedTime : dates[key],
            }
            console.log({ body })
            const res = await createScheduleData({ clientId, body });
            console.log({ res })
            setScheduleIds({
              ...scheduleIds,
              [key]: res?.id,
            })
          }
        } else {
          // Edit
          console.log("Edit", scheduleIds[key], dates[key], key);
          const convertedTime = dateString2Cron(dates[key])
          const body = {
            "to": [
              { "id": decideId(key), "type": decideType(key) }
            ],
            "cron": convertedTime ? convertedTime : dates[key],
          }
          console.log({ body })
          const id = scheduleIds[key]
          const res = await editScheduleData({ clientId, body, id });
          console.log({ res })
          setScheduleIds({
            ...scheduleIds,
            [key]: res?.id,
          })
        }

      })

      syncRef.current = true;   // Forces server sync so that we do not keep creating new schedules 



      function decideId(key) {
        return (
          key === "charge"
            ?
            ("chargeInstruction")
            :
            (key === "deliveries"
              ?
              ("idleWarning")
              :
              (key === "statistics"
                ?
                ("endOfDayStats")
                :
                (null)
              )
            )
        )
      }

      function decideType(key) {
        return (
          key === "charge"
            ?
            ("expo")
            :
            (key === "deliveries"
              ?
              ("email")
              :
              (key === "statistics"
                ?
                ("slack")
                :
                (null)
              )
            )
        )
      }

      function dateString2Cron(dateStr) {
        const hrs = dateStr.toString().split(" ")[4]?.split(":")[0];
        const mins = dateStr.toString().split(" ")[4]?.split(":")[1];
        if (hrs && mins) return cronTime.everyDayAt(hrs, mins);
      }






    }

    async function handleClientInfo(dialog) {
      const postData = {
        command: "updateClientData",
        data: {
          _id: userData._id,
          update: {}
        }
      }
      if (dialog === "department") {
        postData.data.update['department'] = dialogContent.data;
      }
      if (dialog === "pin") {
        postData.data.update['pin'] = dialogContent.data;
      }
      sendDataToFleetController('clients', postData).then(async doc => {
        if (doc.status === 200) {
          await ioHelper.storeData("department", doc.data.department)
          hideDialog();
        } else if (doc.status === 201) {
          setErrorText("Sorry, that PIN is already taken. Please try a different PIN.");
        }
      }).catch(e => {
        console.error(e)
      })
    }





  }







  // Native date picker 
  const [dates, setDates] = useState({});
  const [scheduleIds, setScheduleIds] = useState({});
  const [mode, setMode] = useState('time');
  const [show, setShow] = useState(false);

  const syncRef = React.useRef(null)

  // Sync data with server 
  React.useEffect(() => {

    console.log("-----SYNC-----");
    getScheduleTimes();
    syncRef.current = false;

    async function getScheduleTimes() {
      const cId = await ioHelper.retrieveData("client_id");

      try {

        let scheduleData = await getScheduleData({ clientId: cId })

        let indices = {
          idle: {
            index: null,
            scheduleId: null,
          },
          stats: {
            index: null,
            scheduleId: null,
          },
          charge: {
            index: null,
            scheduleId: null,
          },
        }


        scheduleData = scheduleData.filter(x => x.id?.length > 0)
        console.log({ scheduleData })

        scheduleData.forEach(item => {

          indices["idle"]["index"] = item.to?.findIndex(x => x.id === "idleWarning");
          indices["stats"]["index"] = item.to?.findIndex(x => x.id === "endOfDayStats");
          indices["charge"]["index"] = item.to?.findIndex(x => x.id === "chargeInstruction");

          Object.keys(indices).forEach(key => {
            if (indices[key].index > -1) {
              indices[key]["scheduleId"] = item.id
              indices[key]["cron"] = item.cron
              delete indices[key].index;
            } else if (indices[key].index === -1) delete indices[key].index;
          });

        });

        setScheduleIds({
          deliveries: indices["idle"].scheduleId,
          statistics: indices["stats"].scheduleId,
          charge: indices["charge"].scheduleId,
        })

        setDates({
          deliveries: indices["idle"].cron,
          statistics: indices["stats"].cron,
          charge: indices["charge"].cron,
        })

      } catch (e) {
        console.error(e.toString())
      }



    }

  }, [syncRef.current]);



  const onChange = (event, selectedDate) => {

    if (selectedDate) {
      const type = dialogContent.title.split(' ')[1];
      switch (type) {
        case 'charge':
          setDates({
            ...dates,
            charge: selectedDate,
          });
          break;
        case 'statistics':
          setDates({
            ...dates,
            statistics: selectedDate,
          });
          break;
        case 'deliveries':
          setDates({
            ...dates,
            deliveries: selectedDate,
          });
          break;
      }
    }
    setShow(false);
  };

  const showMode = (currentMode) => {
    setMode(currentMode);
    if (Platform.OS === 'android') {
      setShow(true);
      // for iOS, add a button that closes the picker
    }
  };

  const showDatepicker = () => {
    showMode('date');
  };

  const showTimepicker = () => {
    showMode('time');
  };





  // Web date picker
  const [hours, setHours] = React.useState('0');
  const [minutes, setMinutes] = React.useState('0');
  const handleChange = ({ hours, minutes }) => {

    // const selectedDate = new Date(`2022-04-08T${hours.length < 2 ? "0" + hours : hours}:${minutes.length < 2 ? "0" + minutes : minutes}`);
    let selectedDate = cronTime.everyDayAt(hours, minutes)
    if (selectedDate != "Invalid Date") {

      const type = dialogContent.title.split(' ')[1];
      switch (type) {
        case 'charge':
          setDates({
            ...dates,
            charge: selectedDate,
          });
          break;
        case 'statistics':
          setDates({
            ...dates,
            statistics: selectedDate,
          });
          break;
        case 'deliveries':
          setDates({
            ...dates,
            deliveries: selectedDate,
          });
          break;
      }

    }


    setHours(hours);
    setMinutes(minutes);

  };
  const handleReset = () => {
    setHours('0');
    setMinutes('0');
  };







  return (
    <SafeAreaView style={{
      justifyContent: 'space-evenly',
      display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#fff'
    }}>
      <ScrollView style={styles.view}>


        {/** Client name */}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            Client Name:
          </Text>

          <Text style={styles.text}>
            {userData.clientName}
          </Text>

        </Surface>



        {/** Department  */}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            Department:
          </Text>

          <Text style={styles.text}>
            {userData.department}
          </Text>

          <Button onPress={() => displayEditDialog('department', userData)}
            disabled={userAccessLevel !== "superuser"}
          >
            Edit
          </Button>


        </Surface>



        {/** Current PIN */}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            PIN:
          </Text>

          <Text style={styles.text}>
            {userData.pin}
          </Text>


          <Button onPress={() => displayEditDialog('pin', userData)}>
            Edit
          </Button>


        </Surface>











        {/** Charge time */}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            Charge notification:
          </Text>

          <Text style={styles.text}>
            {`${new Date(cronStr2DateStr(dates.charge)).toTimeString()}`}
          </Text>


          <Button onPress={() => displayEditDialog('charge notification time', userData)}>
            Edit
          </Button>


        </Surface>


        {/** Stats time */}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            Daily stats notification:
          </Text>

          <Text style={styles.text}>
            {`${new Date(cronStr2DateStr(dates.statistics)).toTimeString()}`}
          </Text>


          <Button onPress={() => displayEditDialog('statistics time', userData)}>
            Edit
          </Button>


        </Surface>


        {/** Null deliveries warning time  */}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            Deliveries not started notification:
          </Text>

          <Text style={styles.text}>
            {`${new Date(cronStr2DateStr(dates.deliveries)).toTimeString()}`}
          </Text>


          <Button onPress={() => displayEditDialog('deliveries not started notification time', userData)}>
            Edit
          </Button>


        </Surface>












        {/** Employee ID*/}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            Employee ID:
          </Text>


          <Text style={styles.text}>
            {userData.employeeId}
          </Text>


        </Surface>



        {/** Client ID*/}
        <Surface style={styles.childView}>

          <Text style={styles.headerText}>
            Client ID:
          </Text>

          <Text style={styles.text}>
            {userData.clientId}
          </Text>

        </Surface>


      </ScrollView >

      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Title>{dialogContent.title}</Dialog.Title>
        <Dialog.Content>



          <Paragraph>{dialogContent.text}</Paragraph>

          {dialogContent.title.split(' ')[1] === "department" ? (

            deviceType === 'web' ? (
              <PickerWeb
                type={'department'}
                selected={selectedDepartment}
                setSelected={setSelectedDepartment}
                contents={departmentDropDownListItems}

              />
            ) : (
              <PickerAndroid

                open={openDepartmentsDropdown}
                value={departmentValue}
                placeholder="Select a Department"
                items={departmentDropDownListItems}
                setOpen={setOpenDepartmentsDropdown}
                setValue={setDepartmentValue}
                setItems={setDepartmentDropDownListItems}

              />
            )

          ) : (
            dialogContent.title.split(' ')[1] === "pin" ? (
              <View>

                <Text>
                  Enter a new 4-digit PIN
                </Text>
                <TextInput
                  label=""
                  value={dialogContent.data}
                  maxLength={4}
                  keyboardType='numeric'
                  onChangeText={(input) => {
                    if (/^\d+$/.test(input) || input === "") {
                      setDialogContent({ ...dialogContent, data: input });
                    }
                  }}
                />

              </View>
            ) : (
              dialogContent.title.split(' ')[1] === "charge" ? (
                DTPicker('charge')
              ) : (
                dialogContent.title.split(' ')[1] === "statistics" ? (
                  DTPicker('statistics')
                ) : (
                  dialogContent.title.split(' ')[1] === "deliveries" ? (
                    DTPicker('deliveries')
                  ) : (
                    null
                  )
                )
              )
            )
          )}



          {errorText ? (
            <Paragraph style={{ color: 'red' }}>{errorText}</Paragraph>
          ) : (null)}



        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={submitEditData}>Done</Button>
        </Dialog.Actions>
      </Dialog>








    </SafeAreaView >
  )

  function DTPicker(type) {
    return deviceType === "web" ? (
      <>
        <TimePicker
          value={{ hours, minutes }}
          onChange={handleChange}
          hoursUnit=""
          minutesUnit=""
          // isAmpm
          ampmLocalization={{
            am: 'AM',
            pm: 'PM'
          }} />
      </>

    ) : (
      <View>
        {/* <Button onPress={showDatepicker} title="Show date picker!" /> */}
        <Button onPress={showTimepicker}> Select time</Button>

        {show && (
          <DateTimePicker
            testID={type}
            value={dates[type]}
            mode={mode}
            is24Hour={true}
            onChange={onChange} />
        )}
      </View>
    );
  }
}


const styles = StyleSheet.create({
  childSurface: {
    borderWidth: 1, borderColor: "red", borderStyle: "solid", borderRadius: 10,
    flex: 1, flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: "#ddd",
    elevation: 0,
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginHorizontal: 10,
    marginVertical: 10,
  },
  dropdownPicker: {
    backgroundColor: "#ddd",
    // borderColor: "#111", width: '100%'
  },
  dropdownContainer: {
    borderWidth: 1,
    borderColor: "green",
    borderStyle: "solid",
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  view: {
    margin: 10,
  },
  childView: {
    borderWidth: 1, borderColor: 'black',
    justifyContent: 'space-evenly',
    borderRadius: 10,
    alignItems: 'center',
    margin: 10,
  },
  headerText: {
    margin: 10,
    fontWeight: 'bold', fontSize: 18,
  },
  text: {
    fontSize: 16,
    margin: 10,
  },
});


export default SettingScreen
