import React, { useState } from 'react';
import {
    View, Platform, SafeAreaView,
    ScrollView, StyleSheet
}
    from 'react-native';

import DropDownPicker from 'react-native-dropdown-picker';
import { Picker } from '@react-native-picker/picker';

import { Button, IconButton, Text, TextInput, List, Surface, Snackbar } from 'react-native-paper';

import Loader from './Loader2';


const { sendDataToQueueHandler, sendDataToFleetController } = require('../Helpers/apiHelper');

import ioHelper from "../Helpers/ioHelper";
import { TouchableOpacity } from 'react-native-gesture-handler';


import RobotsContext from '../Context/robotsContext'


const styles = StyleSheet.create({
    incrementorContainer: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    childSurface: {
        borderWidth: 0, borderColor: "red", borderStyle: "solid", borderRadius: 10,
        flex: 1, flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',


        backgroundColor: "#fff",
        elevation: 0,
        paddingHorizontal: 10,
        paddingVertical: 10,
        marginHorizontal: 10,
        marginVertical: 10,
        elevation: 12,
    },
    childSurfaceButton: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        flexDirection: 'row',
        borderRadius: 0,
        marginTop: 10,
        backgroundColor: "#555",
        elevation: 10,
    },
    childSurfaceList: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        elevation: 10,
        backgroundColor: '#222',
        borderRadius: 0,
    },
    scriptsListContainer: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        maxHeight: 300,
        minWidth: '100%',
        backgroundColor: '#eee',
        borderRadius: 10,
        marginTop: 3,
        marginBottom: 10,
        backgroundColor: '#eee',
        elevation: 12,
    },
    scriptIdRowContainer: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#eee',
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
        elevation: 12,
        minWidth: '100%',
    },
    textContainer: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        flex: 0.1,
        flexDirection: 'row',
    },
    textFieldContainer: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",

        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        padding: 0,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    iconButtonContainer: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        flex: 0.3,
        flexDirection: 'row',
    },
    dropdownPickerWeb: {
        borderWidth: 0,
        backgroundColor: "#f0f0f0",
        borderColor: "#000",
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        flexDirection: 'row',
        minHeight: 30,
        minWidth: 300,
        borderRadius: 5,
        color: "#000"
    },
    dropdownPicker: {
        borderWidth: 0,
        backgroundColor: "#f0f0f0",
        borderColor: "#000",
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        flexDirection: 'row',
    },
    dropdownContainer: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        backgroundColor: "#fff",
        minHeight: 50,
        minWidth: 300,
        width: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        flexDirection: 'row',
        borderRadius: 0,
        marginTop: 0,
        marginBottom: 10,
    },
    scriptsListText: {
        borderWidth: 0,
        borderColor: 'black',
        fontSize: 14,
        color: '#eee',
    },
    wardText: {
        color: '#eee',
        fontSize: 14,
    },
    wardTextContainer: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        flex: 0.1,
        flexDirection: 'row',
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    buttonView: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        paddingBottom: 5,
        paddingTop: 5
    },
    listItem: {
        borderWidth: 0,
        borderColor: "green",
        borderRadius: 5,
        borderStyle: "solid",
        padding: 0,
        marginBottom: 10,
        minHeight: 50,
        backgroundColor: "#e1e1e1",
        elevation: 12,
    },
    deleteIconContainer: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 0.4,
        flexDirection: 'row',
    },


    rootScrollView: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        flex: 1, flexDirection: 'column',
        padding: 0
    },
    rootSurface: {
        borderWidth: 0,
        borderColor: "yellow",
        borderStyle: "solid",
        flex: 1,
        elevation: 0,
        backgroundColor: "#fff"
    },
    bodyText: {
        color: '#333',
        fontSize: 18,
    },


    accordionGroupSurface: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        backgroundColor: "#fff",
        marginTop: 5
    },
    accordionGroup: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
    },
    accordion: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        flex: 1,
        position: 'relative',
        backgroundColor: "#ccc",
    },
    accordionSurface: {
        borderWidth: 0,
        borderColor: "#fff",
        borderStyle: "solid",
        flex: 1,
        margin: 5,
        borderRadius: 5,
        elevation: 0,
        backgroundColor: "#888",
    },
    accordionContent: {
        borderWidth: 0,
        borderColor: "yellow",
        borderStyle: "solid",
        flex: 1,
        flexDirection: 'row',
        padding: 5,
        elevation: 12,
        backgroundColor: "#000",
        justifyContent: 'flex-start',
        paddingRight: 10,
        paddingLeft: 10,
    },

    buttonText: {
        color: '#f0f0f0',
        fontSize: 16,
        textTransform: "uppercase",
    },
    button: {
        backgroundColor: '#444',
        borderWidth: 1,
        elevation: 12,
        color: '#FFFFFF',
        borderRadius: 5,
        textAlign: 'center',
    },
    buttonContainer: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 10,
    },

    iconButton: {
        borderWidth: 0,
        borderColor: "black",
        flex: 1,
    },
    iconButtonView: {
        borderWidth: 0,
        borderColor: "black",
        borderStyle: "solid",
        backgroundColor: "#888",
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingRight: 10,
        paddingLeft: 10,
    }


});


const MInput = ({
    orderList,
    setOrderList,
    orderToEdit,
    setOrderToEdit,
    shelfDropDownListItems,
    setShelfDropDownListItems, 
}) => {



    // COMPONENTS
    const PickerWeb = ({ type, selected, setSelected, contents }) => {
        return (
            <>

                <Text style={[styles.bodyText, {
                    marginRight: 10,
                }]}>
                    Select a {type}
                </Text>
                <Picker
                    selectedValue={selected}
                    style={[styles.dropdownPickerWeb, styles.bodyText]}
                    itemStyle={[styles.dropdownPickerWeb, styles.bodyText]}
                    onValueChange={(itemValue) =>
                        setSelected(itemValue)
                    }>
                    {contents.map((x, i) => (
                        <Picker.Item key={`p-${i}`} label={x.label} value={x.value} />
                    ))}
                </Picker>

            </>

        )
    }

    const PickerAndroid = ({
        open, value, placeholder, items, setOpen, setValue, setItems,
    }) => {
        return (
            <DropDownPicker
                itemSeparator={true}
                listMode="MODAL"
                bottomOffset={300}
                open={open}
                value={value}
                placeholder={placeholder}
                items={items}
                setOpen={setOpen}
                setValue={setValue}
                setItems={setItems}
                style={styles.dropdownPicker}
                textStyle={
                    styles.bodyText
                }
                labelStyle={
                    styles.bodyText
                }
                modalContentContainerStyle={{
                    backgroundColor: "#777",
                }}
                itemSeparatorStyle={{
                    color: "#fff",
                    width: "100%",
                }}
                labelProps={{
                    value: "VALUE",
                }}
                theme="DARK"
            />
        )
    }

    const ScriptIdList = ({ scriptIdList, setScriptIdList,
        activeIndex, setActiveIndex }) => {


        const handlePressListItemEdit = (i) => {
            console.log("EDIT " + i);
            setActiveIndex(i);
        }

        const handlePressListItemDelete = (i) => {
            console.log("DELETE " + i)
            const newList = [
                ...scriptIdList,
            ]
            const index = newList.indexOf(scriptIdList[i]);
            if (index > -1) {
                newList.splice(index, 1);
            }
            setScriptIdList(newList);
            setActiveIndex(null);
        }

        const handlePressListItem = (i) => {
            console.log("ITEM " + i);
            setActiveIndex(null);
        }

        let jsx = [];

        for (let i = 0, n = scriptIdList.length; i < n; i++) {
            activeIndex === i ? (
                jsx.unshift(
                    <List.Item key={`li-${i}`}
                        style={styles.listItem}
                        title={`${scriptIdList[i]}`}
                        onPress={() => handlePressListItem(i)}
                        left={props => <List.Icon {...props} icon="script-text" />}
                        right={props => {
                            return (
                                <View
                                    style={styles.deleteIconContainer}
                                >
                                    <IconButton onPress={() => handlePressListItemEdit(i)} {...props} icon="pencil" />
                                    <IconButton color="#CCC" onPress={() => handlePressListItemDelete(i)} {...props} icon="delete" />
                                </View>
                            )
                        }}
                    />
                )
            ) : (
                jsx.unshift(
                    <List.Item key={`li-${i}`}
                        style={styles.listItem}
                        title={`${scriptIdList[i]}`}
                        onPress={() => handlePressListItem(i)}
                        left={props => <List.Icon {...props} icon="script-text" />}
                        right={props => {
                            return (
                                <View
                                    style={styles.deleteIconContainer}
                                >
                                    {/* <IconButton onPress={() => handlePressListItemEdit(i)} {...props} icon="pencil" /> */}
                                    <IconButton color="#CCC" onPress={() => handlePressListItemDelete(i)} {...props} icon="delete" />
                                </View>
                            )
                        }}
                    />
                )
            )
        }


        return (
            <>
                <List.Section style={{
                    borderWidth: 0,
                    borderColor: "blue",
                    borderStyle: "solid",
                    // width: '100%',
                    // maxHeight: '100%',
                    paddingBottom: 10,
                    display: 'flex', flexDirection: 'column', flex: 1,
                }}>
                    <List.Subheader style={styles.bodyText}>Scripts list</List.Subheader>
                    <ScrollView
                        nestedScrollEnabled
                    >
                        {jsx}
                    </ScrollView>

                </List.Section>
            </>
        );
    }



    // STATES

    const [openWardsDropdown, setOpenWardsDropdown] = useState(false);  // For the wards dropdown list 
    const [selectedWard, setSelectedWard] = useState(null);
    const [wardDropDownListItems, setWardDropDownListItems] = useState([]);

    const [openShelfDropdown, setOpenShelfDropdown] = useState(false);  // For the shelves dropdown list 
    const [selectedShelf, setSelectedShelf] = useState(null);


    // Remove shelves that are already part of the orderList
    React.useEffect(() => {
        console.log(shelfDropDownListItems)

        for (let i = 0, n = shelfDropDownListItems.length; i < n; i++) {
            orderList.forEach((item, index) => {
                if (item.shelf === shelfDropDownListItems[i]?.value) shelfDropDownListItems.splice(i, 1);
            })
            setShelfDropDownListItems([...shelfDropDownListItems]);
        }

    }, [orderList])

    React.useEffect(() => {

        console.log("orderToEdit:", orderToEdit)
        if (orderToEdit?.ward) {
            if (scriptIdList.length > 0) {
                alert("Cannot edit. Outstanding script IDs must first be deleted or added to route");
                setOrderToEdit({})
            } else {
                setSelectedWard(orderToEdit.ward);
                setSelectedShelf(orderToEdit.shelf);
                setScriptIdList(orderToEdit.scripts[0]);
                setScriptIdValue('');

                let index = orderList.findIndex(obj => obj === orderToEdit);

                // code below copied from OrderList.handlePressListItemDelete

                shelfDropDownListItems.push({
                    label: `Box ${orderToEdit.shelf}`, value: `${orderToEdit.shelf}`
                })
                setShelfDropDownListItems([...shelfDropDownListItems]);

                orderList.splice(index, 1);
                setOrderList([...orderList]);
                ioHelper.storeData("order_list", JSON.stringify([...orderList]));
                }
        }
    }, [orderToEdit])

    // For scriptId input field + list
    const [scriptIdValue, setScriptIdValue] = useState('');
    const [scriptIdList, setScriptIdList] = useState([]);
    const [activeIndex, setActiveIndex] = React.useState(null);
    const handlePressAddToList = () => {
        if (scriptIdValue.trim()) {
            // pharmacy's barcode string should be either 24 or 25 characters in length, depending on if the backslash character is included in the scan string eg \101424075920221001075160
            // the first ten chars are the case num, the last 10 are the script ID
            console.log("Script Input: ", scriptIdValue);
            let tempScriptIdValue = scriptIdValue.trim().replace(/\\/g, ""); // remove backslash if it is present
            if (tempScriptIdValue.length > 20) {
                // based on string length, can assume that this must be a barcode scan input
                tempScriptIdValue = tempScriptIdValue.substring(tempScriptIdValue.length - 10); // script ID
            }
            setScriptIdList([
                ...scriptIdList,
                tempScriptIdValue
            ]);
            setScriptIdValue('');
            setActiveIndex(null)
        }
    }


    // Get the list of departments from a user's clientId
    React.useEffect(() => {

        async function getDepartmentsList() {
            const clientId = await ioHelper.retrieveData("client_id");
            const postData = {
                command: "getDepartments",
                data: {
                    clientId: clientId,
                },
            };

            const { data } = await sendDataToFleetController('robots', postData);
            if (data?.departments) {
                setWardDropDownListItems(data.departments.map(x => {
                    return { label: x, value: x }
                }))
            }
        }

        getDepartmentsList();

    }, [])


    // For the web dropdowns 
    if (Platform.OS === 'web') {
        React.useEffect(() => {

            if (!selectedShelf | !selectedWard) {
                setSelectedShelf(shelfDropDownListItems[0]?.value)
                setSelectedWard(wardDropDownListItems[0]?.value)
            }

        }, [shelfDropDownListItems, wardDropDownListItems])
    }



    // Checks if a new robot has changed from a busy state to a free
    // state and vice versa 
    const context = React.useContext(RobotsContext);
    const [busyChange, setBusyChange] = React.useState(0)
    React.useEffect(() => {

        const taskStates = Object.values(context.announcement).map(x => x.taskStatus);
        console.log("STATES", taskStates);
        if (taskStates.includes("completed") | taskStates.includes("pending")) setBusyChange(Math.random());

    }, [context.announcement]);
    // Grab available robots and associated capacities
    React.useEffect(() => {

        async function constructBoxDropdownList() {

            const clientId = await ioHelper.retrieveData("client_id");
            const allRobots = (
                await context.client.service("robots").find({
                    query: {
                        clientId: clientId
                    }
                })
            ).data;
            let compartments = [];
            for (let i = 0, n = allRobots.length; i < n; i++) {
                const robot = allRobots[i];
                const robotAvailable = (
                    await context.client.service("announcements").find({
                        query: {
                            $limit: 1,
                            "assignee.robotId": robot.robotId,
                            taskStatus: {
                                $in: [
                                    "pending",

                                    "dispatching",
                                    "dispatched",

                                    "in-progress",
                                    "failed",

                                    "arrived",
                                    "offloading",
                                    "offloaded",

                                    "returning",

                                    "alarm",
                                    "processing...",
                                    "rejected",
                                ]
                            }
                        }
                    })
                ).data.length === 0
                console.log("robotAvailable", robotAvailable)
                if (robotAvailable) compartments.push(robot.robotData.capacity)
            }
            let max = getMaxArrayValue(compartments) || 5;    // Defaults to 5 boxes if all robots are busy

            // Set the max number of boxes 
            console.log("Max", max);
            let tmp = [];
            const storedOrderList = await ioHelper.retrieveData('order_list');
            const storedShelves = storedOrderList ? JSON.parse(storedOrderList).map(x => x.shelf) : [];
            const selectedShelves = orderList.map(x => x.shelf);
            console.log("SLECTED", selectedShelves, "STORED", storedShelves);
            for (let i = 0, n = max; i < n; i++) {
                const interferesWithOrderList = selectedShelves.includes((i + 1).toString()) | storedShelves.includes((i + 1).toString());
                if (!interferesWithOrderList) tmp.push({ label: `Box ${i + 1}`, value: `${i + 1}` })
            }
            setShelfDropDownListItems(tmp);



            function getMaxArrayValue(compartments) {
                if (typeof compartments === "object" && compartments[0]) return Math.max(...compartments);
                else return null;
            }


        }

        constructBoxDropdownList();

    }, [
        busyChange,
    ]);




    // METHODS
    const handleAdd = async () => {
        if (!selectedWard) {
            alert("Select a ward");
        }
        else if (!selectedShelf) {
            alert("Select a box");
        }
        else if (scriptIdList.length === 0) {
            alert("Enter at least one script ID, and then click \"ADD TO BOX\"");
        }
        else {
            orderList.push({
                ward: selectedWard,
                scripts: [scriptIdList],    // Preserve this array structure as queue-handler depends on it
                shelf: selectedShelf,
            })
            setOrderList([...orderList]);
            setSelectedShelf(null); 
            ioHelper.storeData("order_list", JSON.stringify([...orderList]));
            clearForm();
        }

    }

    const clearForm = () => {
        setScriptIdValue('');
        setSelectedWard(null);
        setSelectedShelf(null);
        setScriptIdList([]);
    }


    return (
        <>

            < Surface
                style={styles.childSurface}
            >


                {/** Wards  */}
                <View
                    style={styles.dropdownContainer}>


                    {Platform.OS === 'web' ? (
                        <PickerWeb
                            type={'ward'}
                            selected={selectedWard}
                            setSelected={setSelectedWard}
                            contents={wardDropDownListItems}

                        />
                    ) : (
                        <PickerAndroid

                            open={openWardsDropdown}
                            value={selectedWard}
                            placeholder="Select a ward"
                            items={wardDropDownListItems}
                            setOpen={setOpenWardsDropdown}
                            setValue={setSelectedWard}
                            setItems={setWardDropDownListItems}

                        />
                    )}

                </View>


                {/** Shelf */}
                <View
                    style={styles.dropdownContainer}>

                    {
                        Platform.OS === 'web' ? (
                            <PickerWeb
                                type={'box'}
                                selected={selectedShelf}
                                setSelected={setSelectedShelf}
                                contents={shelfDropDownListItems}

                            />
                        ) : (
                            <PickerAndroid

                                open={openShelfDropdown}
                                value={selectedShelf}
                                placeholder="Select a box"
                                items={shelfDropDownListItems}
                                setOpen={setOpenShelfDropdown}
                                setValue={setSelectedShelf}
                                setItems={setShelfDropDownListItems}

                            />
                        )

                    }

                </View>


                {/** The scriptId list */}
                <View
                    style={styles.scriptsListContainer}
                >
                    <ScriptIdList
                        scriptIdList={scriptIdList}
                        setScriptIdList={setScriptIdList}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />
                </View>

                {/** The scriptId input field */}
                <View
                    style={styles.scriptIdRowContainer}
                >


                    <View
                        style={[styles.textContainer, {
                            justifyContent: 'flex-start',
                        }]}>
                        <Text>
                            Script ID
                        </Text>
                    </View>

                    <View
                        style={styles.textFieldContainer}>
                        <TextInput
                            blurOnSubmit={false}
                            onSubmitEditing={handlePressAddToList}
                            onFocus={() => setActiveIndex(null)}
                            mode='flat'
                            style={{
                                width: '100%'
                            }}
                            dense
                            value={scriptIdValue}
                            onChangeText={setScriptIdValue}
                        >
                        </TextInput>
                    </View>

                    <View
                        style={styles.iconButtonContainer}>

                        <TouchableOpacity
                            onPress={handlePressAddToList}
                        >

                            {/* <Svg viewBox="0 0 512 512" width="40" height="40">
                                <Path
                                    className="st0"
                                    fill="#0000ff" d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm80 224h-64v64a16 16 0 01-32 0v-64h-64a16 16 0 010-32h64v-64a16 16 0 0132 0v64h64a16 16 0 010 32z"
                                />
                            </Svg> */}

                            <View style={{
                                borderWidth: 0, borderColor: '#000', borderStyle: 'solid',
                                borderRadius: 5,
                                backgroundColor: '#0000ff',
                                padding: 5,

                                flex: 1, flexDirection: 'row', justifyContent: 'center',

                            }}>

                                <Text style={{
                                    textAlign: 'center', fontSize: 11, color: '#fff',
                                    fontWeight: 'bold', textTransform: 'uppercase',
                                }}>
                                    Add to box {selectedShelf}
                                </Text>


                            </View>


                        </TouchableOpacity>

                    </View>

                </View>



                {/** Add demand button */}
                <View
                    style={[styles.buttonContainer]}
                >
                    <Button
                        style={[styles.button, { backgroundColor: "#404040" }]}
                        mode='contained'
                        onPress={handleAdd}
                    >
                        Add to route
                    </Button>
                </View>


            </Surface >


        </>

    )
}


const OrderList = ({ orderList, setOrderList, setOrderToEdit,
    shelfDropDownListItems, setShelfDropDownListItems }) => {
    let jsx = [];
    orderList.forEach((item, index) => {
        const handlePressListItemEdit = (i) => {
            console.log("EDIT " + index);

            setOrderToEdit(orderList[index])

            // handlePressListItemDelete(index)

        }
        const handlePressListItemDelete = (i) => {
            console.log("DELETE " + index)

            console.log(orderList[index].shelf)
            shelfDropDownListItems.push({
                label: `Box ${shelf}`, value: `${shelf}`
            })
            setShelfDropDownListItems([...shelfDropDownListItems]);

            orderList.splice(index, 1);
            setOrderList([...orderList]);
            ioHelper.storeData("order_list", JSON.stringify([...orderList]));
        }
        const { ward, scripts, shelf } = item;
        jsx.push(
            <Surface key={`l-acc-grp-${index}`} style={{
                display: 'flex', flexDirection: 'row',
                justifyContent: 'space-between', alignItems: 'center',
                borderWidth: 0, elevation: 12,
                paddingHorizontal: 5,
                paddingLeft: 15,
                marginHorizontal: 10,
                marginVertical: 4,
                borderRadius: 10,
            }}>

                <Text style={[styles.bodyText, {
                    display: 'flex', flex: 1,
                }]}>
                    {ward}
                </Text>

                <Text style={[styles.bodyText, {
                    display: 'flex', flex: 1,
                    padding: 5, marginRight: 5
                }]}>
                    {scripts[0].join(' + ')}
                </Text>

                <Text style={[styles.bodyText, {
                    display: 'flex', flex: 1,
                }]}>
                    Box: {shelf}
                </Text>

                <IconButton
                    style={{
                        display: 'flex', flex: 1, maxWidth: 40,
                    }}
                    icon="pencil"
                    onPress={() => handlePressListItemEdit(index)}
                />

                <IconButton
                    style={{
                        display: 'flex', flex: 1, maxWidth: 40,
                    }}
                    icon="delete"
                    onPress={() => handlePressListItemDelete(index)}
                    color="#ff0000"
                />


            </Surface>
        )
    });
    return jsx;
}


function PhoneDemandScreen({ navigation }) {

    // STATES
    const [snackbarContent, setSnackbarContent] = React.useState({})
    const [visible, setSnackbarVisible] = React.useState(false);
    const [orderList, setOrderList] = React.useState([]);   // For the accordion components
    const [orderToEdit, setOrderToEdit] = React.useState({});

    React.useEffect(() => {                                 // Check for stale orders
        async function getStaleOrderList() {
            let sLst = await ioHelper.retrieveData("order_list");
            if (sLst) {
                sLst = JSON.parse(sLst);
                setOrderList([...sLst]);
            }
        }
        getStaleOrderList();
    }, []);
    const [loading, setLoading] = React.useState(false);    // For the loading animation behind the submit button

    const [shelfDropDownListItems, setShelfDropDownListItems] = useState([]);

    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    React.useEffect(() => {
        if (orderList.length > 0) setSubmitDisabled(false)
        else setSubmitDisabled(true)
    }, [orderList]);


    const [dispatcherApproved, setDispatcherApproved] = React.useState(false);
    const context = React.useContext(RobotsContext);
    React.useEffect(() => {
        async function getDispatcherApprovedStatus() {
            setDispatcherApproved(context.dispatchStatus);
        }
        getDispatcherApprovedStatus();
    })

    // METHODS
    const onDismissSnackBar = () => setSnackbarVisible(false);

    const handleSnackbarAction = () => {
        if (snackbarContent.title === "Response") {
            navigation.navigate('loadScreenStack', snackbarContent.res.data)
        }
    }

    const handleSubmit = async () => {
        console.log("SUBMIT");
        if (orderList.length < 1) {
            alert("Cannot submit an empty demand. Add a task before submitting.")
            return;
        }
        let reqBody = {};
        let wardValues = [];
        let scriptIdLists = [];
        let shelves = [];
        orderList.forEach(item => {
            wardValues.push(item.ward)
            scriptIdLists.push(item.scripts)
            shelves.push(item.shelf)
        })
        const clientId = await ioHelper.retrieveData("client_id");
        reqBody[`wardValues`] = wardValues;
        reqBody[`scriptIdLists`] = scriptIdLists;
        reqBody[`shelves`] = shelves;
        reqBody[`clientId`] = clientId;

        setLoading(true);
        sendDataToQueueHandler(reqBody).then(obj => {
            const res = obj.res;
            const status = obj.status;
            setLoading(false);
            if (status === 200) {

                // const rejected = res.data.rejected;
                // const accepted = res.data.accepted;
                // let acceptedRobots = [];
                // let rejectionReasons = [];

                // if (rejected.length > 0) {
                //     for (let i = 0, n = rejected.length; i < n; i++) {
                //         rejectionReasons.push(rejected[i].rejectionReason)
                //     }
                //     console.log("REJECTED", rejectionReasons)
                // }

                // if (accepted.length > 0) {
                //     for (let i = 0, n = accepted.length; i < n; i++) {
                //         acceptedRobots.push(accepted[i].assignee.alias)
                //     }
                //     console.log("ACCEPTED", rejectionReasons)
                // }

                setSnackbarContent({
                    title: "Response",
                    message: res.msg,
                    res: res,
                })
                setSnackbarVisible(true)

                setOrderList([]);
                ioHelper.removeData("order_list");

                navigation.navigate('LOAD', res.data)

            } else if (status === 201) {
                setSnackbarContent({
                    title: "Error",
                    message: res.message
                })
                setSnackbarVisible(true)
            };
        }).catch(err => {
            console.error(err);
            setLoading(false);
            alert("Request failed")
        })
    }

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>



            {dispatcherApproved ? (
                <Surface style={styles.rootSurface}>
                    <ScrollView
                        style={styles.rootScrollView}
                    >


                        {/** Input fields */}
                        <MInput
                            orderList={orderList}
                            setOrderList={setOrderList}
                            orderToEdit={orderToEdit}
                            setOrderToEdit={setOrderToEdit}
                            shelfDropDownListItems={shelfDropDownListItems}
                            setShelfDropDownListItems={setShelfDropDownListItems}
                        />




                        {/** The accordions */}
                        <Surface style={styles.accordionGroupSurface}>
                            <List.AccordionGroup style={styles.accordionGroup}>
                                <OrderList setOrderList={setOrderList} orderList={orderList}
                                    setOrderToEdit={setOrderToEdit}
                                    shelfDropDownListItems={shelfDropDownListItems}
                                    setShelfDropDownListItems={setShelfDropDownListItems}
                                />
                            </List.AccordionGroup>
                        </Surface>


                        {/** Submit */}
                        <View style={[, styles.buttonContainer, {
                            alignItems: 'center',
                            paddingTop: 10,
                        }]}>
                            {loading ? (
                                <Loader />
                            ) : (
                                <Button
                                    disabled={submitDisabled}
                                    style={[styles.button, { backgroundColor: "#404040" }]}
                                    mode='contained'
                                    onPress={handleSubmit}
                                >
                                    Submit
                                </Button>
                            )}
                        </View>




                    </ScrollView>


                </Surface>
            ) : (
                <Surface style={styles.rootSurface}>

                    <Text>
                        User not approved for dispatch. Please contact department manager.
                    </Text>

                </Surface>
            )}




            <Snackbar
                visible={visible}
                onDismiss={onDismissSnackBar}
                action={{
                    label: 'OK',
                    onPress: handleSnackbarAction,
                }}
                duration={3000}
            >
                <Text style={[styles.bodyText, { color: "#FF0000" }]}>
                    {snackbarContent.message}
                </Text>
            </Snackbar>


        </SafeAreaView >
    )
}

export default PhoneDemandScreen
