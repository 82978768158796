import { Platform } from 'react-native';
import PadDemandScreen from '../Components/PadDemandScreen';
import PhoneDemandScreen from '../Components/PhoneDemandScreen';

function SendScreen({navigation, client}) {
    
    if (Platform.isPad || Platform.OS === "web") {
        return <PadDemandScreen client={client} navigation={navigation}/>;
    }
    return <PhoneDemandScreen client={client} navigation={navigation}/>;
}

export default SendScreen