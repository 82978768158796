import React from 'react'
import {
    View, SafeAreaView, Image, ScrollView,
    StyleSheet, Platform
} from 'react-native';

import { Text, Surface } from 'react-native-paper';
import { sendDataToFleetController } from '../Helpers/apiHelper';
import ioHelper from '../Helpers/ioHelper';

import {
    VictoryLine, VictoryBar, VictoryHistogram, VictoryChart, VictoryLegend,
    VictoryGroup, VictoryVoronoiContainer, VictoryTooltip, VictoryLabel,
    VictoryZoomContainer, VictoryBrushContainer, VictoryClipContainer,
    VictoryAxis, VictoryTheme, VictoryStack
} from "victory-native";


function AnalyticsScreen({ navigation, client }) {

    const [clientId, setClientId] = React.useState('');
    const [clientName, setClientName] = React.useState('');
    const [departments, setDepartments] = React.useState([]);
    const [dispatchers, setDispatchers] = React.useState([]);
    const [robots, setRobots] = React.useState([]);
    const [analyticsData, setAnalyticsData] = React.useState({});

    React.useEffect(() => {
        getClientId();
    }, []);

    React.useEffect(() => {
        if (clientId) {
            console.log("CLIENTID", clientId);
            getClientName();
            getAnalyticsData();
            getDepartments();
            getDispatchers();
            getRobots();
        }

    }, [clientId]);

    //   function handleZoom(domain) {
    //       setZoomDomain(domain);
    //   }

    function firstCaps(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const getClientId = async () => {
        const data = await ioHelper.retrieveData("client_id");
        setClientId(data);
    }

    const getClientName = async () => {
        const postData = {
            "command": "getClientName",
            "data": { clientId }
        };

        const { data } = await sendDataToFleetController('robots', postData);
        console.log("CLIENTNAME", data.clientName);
        setClientName(data.clientName);
    }

    const getAnalyticsData = async () => {
        const pipeline = [
            {
                "$match": {
                    "clientId": clientId,
                    "assignee.dispatchedBy": { "$exists": true },
                    "timestamps.dispatched": { "$exists": true },
                    "timestamps.arrived": { "$exists": true },
                    "timestamps.offloaded": { "$exists": true },
                }
            },

            {
                "$addFields": {
                    "day": { "$dateTrunc": { "date": "$createdAt", "unit": "day" } },
                    "hour": { "$dateTrunc": { "date": "$createdAt", "unit": "hour" } }
                }
            },

            {
                "$facet": {

                    "numDeliveries": [
                        {
                            "$group": {
                                "_id": "$day",
                                "numTrips": { "$count": {} },
                                "numDeliveries": { "$sum": { "$size": "$assignee.compartments" } },
                                "numItems": {
                                    "$sum": {
                                        "$reduce": {
                                            "input": "$assignee.compartments",
                                            "initialValue": 0,
                                            "in": { "$sum": ["$$value", { "$size": "$$this.items" }] }
                                        }
                                    }
                                }
                            }
                        },

                        { "$addFields": { "date": "$_id" } },
                        { "$sort": { "date": 1 } },
                        { "$project": { "_id": 0 } },
                    ],

                    "tripsPerDispatcher": [

                        {
                            "$group": {
                                "_id": { "dispatcher": "$assignee.dispatchedBy", "day": "$day" },
                                "numTrips": { "$count": {} }
                            }
                        },

                        { "$sort": { "_id.day": 1 } },

                        {
                            "$group": {
                                "_id": "$_id.dispatcher",
                                "v": { "$push": { "date": "$_id.day", "numTrips": "$numTrips" } }
                            }
                        },

                        { "$project": { "_id": 0, "k": "$_id", "v": 1 } },
                    ],

                    "tripsPerRobot": [
                        {
                            "$group": {
                                "_id": { "robot": "$robotId", "day": "$day" },
                                "numTrips": { "$count": {} }
                            }
                        },

                        { "$sort": { "_id.day": 1 } },

                        {
                            "$group": {
                                "_id": "$_id.robot",
                                "v": { "$push": { "date": "$_id.day", "numTrips": "$numTrips" } }
                            }
                        },

                        { "$project": { "_id": 0, "k": "$_id", "v": 1 } },
                    ],

                    "tripsPerHour": [
                        { "$group": { "_id": "$hour", "numTrips": { "$count": {} } } },

                        { "$addFields": { "date": "$_id" } },
                        { "$sort": { "date": 1 } },
                        { "$project": { "_id": 0 } },
                    ],

                    "aveWaitTimes": [
                        {
                            "$project": {
                                "day": 1,
                                "destinations": {
                                    "$slice": ["$assignee.destinationQueue", 1, { "$subtract": [{ "$size": "$assignee.destinationQueue" }, 2] }]
                                },
                                "waitTime": {
                                    "$function": {
                                        "body": `function(offloaded, arrived) {
                                    const maxIndex = offloaded.length - 1;
                                    let destIndex = 0, 
                                        offloadedTime = new Date(0),
                                        newArrived = [];
        
                                    for (let i = 0; i < arrived.length; i++) {
                                        const arrivedTime = new Date(arrived[i]);
        
                                        if (arrivedTime > offloadedTime) {
                                            offloadedTime = new Date(offloaded[destIndex]);
                                            newArrived.push((offloadedTime - arrivedTime) / 60000);
                                            
                                            if (destIndex < maxIndex) destIndex += 1; else break;
                                        }
                                    }
        
                                    return newArrived;
                                }`,
                                        "args": [
                                            {
                                                "$cond": {
                                                    "if": { "$isArray": "$timestamps.offloaded" },
                                                    "then": "$timestamps.offloaded",
                                                    "else": ["$timestamps.offloaded"]
                                                }
                                            },
                                            {
                                                "$cond": {
                                                    "if": { "$isArray": "$timestamps.arrived" },
                                                    "then": "$timestamps.arrived",
                                                    "else": ["$timestamps.arrived"]
                                                }
                                            }
                                        ],
                                        "lang": "js"
                                    }
                                }
                            }
                        },

                        { "$unwind": { "path": "$destinations", "includeArrayIndex": "index" } },
                        { "$set": { "waitTime": { "$arrayElemAt": ["$waitTime", "$index"] } } },
                        { "$match": { "waitTime": { "$gt": 0 } } },

                        {
                            "$group": {
                                "_id": { "destination": "$destinations", "day": "$day" },
                                "aveWaitTime": { "$avg": "$waitTime" }
                            }
                        },

                        { "$sort": { "_id.day": 1 } },

                        {
                            "$group": {
                                "_id": "$_id.destination",
                                "v": { "$push": { "date": "$_id.day", "aveWaitTime": { "$round": ["$aveWaitTime", 2] } } }
                            }
                        },

                        { "$project": { "_id": 0, "k": "$_id", "v": 1 } },
                    ],

                    "aveTripTimes": [
                        { "$match": { "timestamps.completed": { "$exists": true } } },

                        {
                            "$group": {
                                "_id": "$day",
                                "aveTripTime": {
                                    "$avg": {
                                        "$dateDiff": {
                                            "startDate": {
                                                "$toDate": {
                                                    "$cond": {
                                                        "if": { "$isArray": "$timestamps.dispatching" },
                                                        "then": { "$first": "$timestamps.dispatching" },
                                                        "else": "$timestamps.dispatching"
                                                    }
                                                }
                                            },
                                            "endDate": {
                                                "$toDate": {
                                                    "$cond": {
                                                        "if": { "$isArray": "$timestamps.completed" },
                                                        "then": { "$first": "$timestamps.completed" },
                                                        "else": "$timestamps.completed"
                                                    }
                                                }
                                            },
                                            "unit": "minute"
                                        }
                                    }
                                }
                            }
                        },

                        { "$match": { "aveTripTime": { "$ne": null } } },
                        { "$project": { "_id": 0, "date": "$_id", "aveTripTime": { "$round": ["$aveTripTime", 2] } } },
                        { "$sort": { "date": 1 } }
                    ]
                }
            },

            {
                "$set": {
                    "tripsPerDispatcher": { "$arrayToObject": "$tripsPerDispatcher" },
                    "tripsPerRobot": { "$arrayToObject": "$tripsPerRobot" },
                    "aveWaitTimes": { "$arrayToObject": "$aveWaitTimes" },
                }
            }
        ];

        try {
            const [data] = await client.service("announcements").find({
                query: { aggregate: pipeline }
            });

            reviveDates(data, 'date');
            getCumulativeDeliveries(data, 'numDeliveries');
            setAnalyticsData(data);
            console.log("ANALYTICS:", data);

        } catch (error) {
            console.error(error);
        }
    }

    const reviveDates = (data, dateKey) => {
        if (Array.isArray(data)) {
            data.forEach(element => reviveDates(element, dateKey))
        } else if (typeof data === 'object') {
            if (Object.keys(data).includes(dateKey)) {
                data[dateKey] = new Date(data[dateKey]);
            } else {
                reviveDates(Object.values(data), dateKey)
            }
        } else {
            console.log(data, "IS NOT ARRAY OR OBJECT");
        }
    }

    const getCumulativeDeliveries = (object, key) => {
        let cumulative = object[key].map(o => { return { ...o } });
        const keys = Object.keys(cumulative[0]).filter(key => key !== "date");

        for (let i = 1; i < cumulative.length; i++) {
            keys.forEach(key => {
                cumulative[i][key] += cumulative[i - 1][key];
            });
        }
        object["cumulativeDeliveries"] = cumulative;
        console.log("CUMULATIVE", cumulative);
    }


    const getDepartments = async () => {
        const postData = {
            command: "getDepartments",
            data: { clientId },
        };

        const { data } = await sendDataToFleetController('robots', postData);

        if (data?.departments) {
            const deps = data.departments.filter(dep => !dep.toLowerCase().match(/test|pharmacy/g));
            console.log("DEPARTMENTS:", deps);
            setDepartments(deps);
        }

        // let deps = [];
        // aveWaitTimes.forEach(({ aveWaitTime }) => {
        //     const newDeps = Object.keys(aveWaitTime).filter(dep => !dep.toLowerCase().match( /test|pharmacy/g ) && !deps.includes(dep));
        //     deps = deps.concat(newDeps);
        // } )
    }

    const getDispatchers = async () => {
        const query = { role: "dispatcher", dispatchStatus: "approved" };

        const postData = {
            "command": "getAllUsersForClient",
            "data": { clientId, query, "page": 0, "size": 100 }
        };

        const { docs } = await sendDataToFleetController('clients', postData);

        const aliasesToIgnore = ["taahir", "dispatcher"];

        const d = docs?.reduce((filteredArr, currDoc) => {
            if (!aliasesToIgnore.includes(currDoc['alias'])) {
                filteredArr.push(currDoc['alias']);
            }

            return filteredArr;
        }, []);

        console.log("DISPATCHERS", d);
        setDispatchers(d);
    }

    const getRobots = async () => {
        const postData = {
            "command": "getClientRobots",
            "data": { clientId }
        };

        const { data } = await sendDataToFleetController('robots', postData);
        const robots = data?.robots.map(obj => ({alias: obj.alias, robotId: obj.robotId}));
        console.log("ROBOTS", robots);
        setRobots(robots);
    }

    const MultiLineChart = ({ title, data, xKey, yKeys, legend, domain }) => {

        return (
            <>
                <Text style={styles.viewTitle}>{title}</Text>

                <VictoryChart
                    theme={VictoryTheme.material}
                    containerComponent={
                        <VictoryVoronoiContainer
                            voronoiDimension="x"
                            labels={({ datum }) => `${datum.childName}: ${datum._y}`}
                            labelComponent={
                                <VictoryTooltip
                                    constrainToVisibleArea
                                    // pointerOrientation="right"
                                    pointerLength={100}
                                    centerOffset={{ x: -50, y: -50 }}
                                />
                            }
                        />
                    }
                // containerComponent={
                //     <VictoryZoomContainer
                //         zoomDimension="x"
                //         zoomDomain={domain}
                //         onZoomDomainChange={setDomain}
                //     />
                // }
                >

                    <VictoryAxis
                        tickFormat={x => new Date(x).toLocaleDateString()}
                        style={chartStyles.xAxis}
                    />

                    <VictoryAxis
                        dependentAxis
                        style={chartStyles.yAxis}
                    />

                    <VictoryLegend
                        orientation="horizontal"
                        colorScale="qualitative"
                        gutter={50}
                        x={40}
                        data={legend.map(name => ({ name }))}
                        style={chartStyles.legend}
                    />

                    <VictoryGroup
                        theme={VictoryTheme.material}
                        colorScale="qualitative"
                        domain={domain}
                    >

                        {
                            yKeys.map((yKey, i) => (
                                <VictoryLine
                                    key={yKey}
                                    name={legend[i]}
                                    style={chartStyles.tooltip}
                                    data={data}
                                    x={xKey}
                                    y={yKey}
                                    groupComponent={<VictoryClipContainer />}
                                />
                            ))
                        }

                    </VictoryGroup>

                </VictoryChart>
            </>
        )
    }

    const StackedBarChart = ({ title, data, xKey, yKey, legend, domain }) => {
        const filteredLegend = legend.filter(leg => data[leg] !== undefined); // remove elements that are not present as keys in data
        const paddingTop = filteredLegend?.length ? filteredLegend.length / 4 * 25 + 20 : 20

        return (
            <>
                <Text style={styles.viewTitle}>{title}</Text>

                <VictoryChart
                    theme={VictoryTheme.material}
                    padding={{ top: paddingTop, bottom: 60, right: 20, left: 40 }}
                    containerComponent={
                        <VictoryVoronoiContainer
                            voronoiDimension="x"
                            labels={({ datum }) => datum[yKey] ? `${datum.childName}: ${datum[yKey]}` : datum[yKey]}
                            labelComponent={
                                <VictoryTooltip
                                    constrainToVisibleArea
                                    // pointerOrientation="right"
                                    pointerLength={100}
                                    centerOffset={{ x: -50, y: -50 }}
                                />
                            }
                        />
                    }
                >

                    <VictoryLegend
                        orientation="horizontal"
                        colorScale="qualitative"
                        gutter={25}
                        itemsPerRow={4}
                        data={filteredLegend.map(name => ({ name }))}
                        standalone={false}
                        style={chartStyles.legend}
                    />

                    <VictoryAxis
                        tickFormat={x => new Date(x).toLocaleDateString()}
                        style={chartStyles.xAxis}
                    />

                    <VictoryAxis
                        dependentAxis
                        axisLabelComponent={<VictoryLabel />}
                        style={chartStyles.yAxis}
                    />

                    <VictoryStack
                        colorScale="qualitative"
                        domainPadding={{ x: 5 }}
                        domain={domain}
                    >

                        {
                            filteredLegend.map(dep => (
                                <VictoryBar
                                    key={dep}
                                    name={dep}
                                    barRatio={3}
                                    style={chartStyles.tooltip}
                                    data={data[dep]}
                                    x={xKey}
                                    y={yKey}
                                    groupComponent={<VictoryClipContainer />}
                                />
                            ))
                        }

                    </VictoryStack>

                </VictoryChart>
            </>
        )
    }


    return (

        <ScrollView
        // contentContainerStyle={{flexDirection: 'row', flex: 1, flexWrap: 'wrap', justifyContent: 'space-evenly'}}
        >

            < Surface
                style={{
                    alignItems: 'center',
                    padding: 20,
                    elevation: 5,
                }}
            >
                <Text style={styles.title}>{clientName ? clientName : "Loading..."}</Text>
            </Surface>

            {Object.keys(analyticsData).length > 0 ? (
                <>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                        < Surface
                            style={styles.statView}
                        >
                            <Text style={styles.viewTitle}>Total Scripts</Text>
                            <Text style={styles.statText}>{analyticsData.cumulativeDeliveries[analyticsData.cumulativeDeliveries.length - 1].numItems}</Text>
                        </Surface>

                        < Surface
                            style={styles.statView}
                        >
                            <Text style={styles.viewTitle}>Total Deliveries</Text>
                            <Text style={styles.statText}>{analyticsData.cumulativeDeliveries[analyticsData.cumulativeDeliveries.length - 1].numDeliveries}</Text>
                        </Surface>

                        < Surface
                            style={styles.statView}
                        >
                            <Text style={styles.viewTitle}>Total Trips</Text>
                            <Text style={styles.statText}>{analyticsData.cumulativeDeliveries[analyticsData.cumulativeDeliveries.length - 1].numTrips}</Text>
                        </Surface>
                    </View>

                    <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', justifyContent: 'space-evenly' }}>

                        < Surface
                            style={styles.chartView}
                        >
                            <MultiLineChart
                                title='Cumulative Number of Deliveries'
                                data={analyticsData.cumulativeDeliveries}
                                xKey='date'
                                yKeys={['numItems', 'numDeliveries', 'numTrips']}
                                legend={['Scripts', 'Deliveries', 'Trips']}
                            />
                        </Surface>


                        < Surface
                            style={styles.chartView}
                        >
                            <MultiLineChart
                                title='Number of Deliveries'
                                data={analyticsData.numDeliveries}
                                xKey='date'
                                yKeys={['numItems', 'numDeliveries', 'numTrips']}
                                legend={['Scripts', 'Deliveries', 'Trips']}
                                domain={{ x: [new Date(Date.now() - (31 * 24 * 3600000)), new Date()] }}
                            />
                        </Surface>

                        < Surface
                            style={styles.chartView}
                        >
                            <StackedBarChart
                                title="Average Waiting Time"
                                data={analyticsData.aveWaitTimes}
                                xKey='date'
                                yKey='aveWaitTime'
                                legend={departments}
                                domain={{ x: [new Date(Date.now() - (15 * 24 * 3600000)), new Date()], y: [0, 60] }}
                            />
                        </Surface>

                        < Surface
                            style={styles.chartView}
                        >
                            <StackedBarChart
                                title='Dispatcher Performance'
                                data={analyticsData.tripsPerDispatcher}
                                xKey='date'
                                yKey='numTrips'
                                legend={dispatchers}
                                domain={{ x: [new Date(Date.now() - (15 * 24 * 3600000)), new Date()], y: [0, 20] }}
                            />
                        </Surface>

                        < Surface
                            style={styles.chartView}
                        >
                            <Text style={styles.viewTitle}>Average Trip Time</Text>

                            <VictoryChart
                                theme={VictoryTheme.material}
                                domain={{ x: [new Date(Date.now() - 15 * 24 * 3600000), new Date()], y: [0, 40] }}
                                domainPadding={{ x: 10 }}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        voronoiDimension="x"
                                        labels={({ datum }) => datum._y ? `${Math.trunc(datum._y)} mins ${Math.round(datum._y % 1 * 60)} secs` : datum._y}
                                        labelComponent={
                                            <VictoryTooltip
                                                constrainToVisibleArea
                                                // pointerOrientation="right"
                                                pointerLength={50}
                                                centerOffset={{ x: -25, y: -25 }}
                                            />
                                        }
                                    />
                                }
                            >

                                <VictoryAxis
                                    tickFormat={x => new Date(x).toLocaleDateString()}
                                    style={chartStyles.xAxis}
                                />

                                <VictoryAxis
                                    dependentAxis
                                    style={chartStyles.yAxis}
                                />

                                <VictoryBar
                                    // colorScale="qualitative"
                                    barRatio={4}
                                    style={chartStyles.tooltip}
                                    data={analyticsData.aveTripTimes}
                                    x='date'
                                    y='aveTripTime'
                                    groupComponent={<VictoryClipContainer />}
                                />

                            </VictoryChart>

                        </Surface>

                        < Surface
                            style={styles.chartView}
                        >
                            <StackedBarChart
                                title='Trips per Robot'
                                data={analyticsData.tripsPerRobot}
                                xKey='date'
                                yKey='numTrips'
                                legend={robots.map(robot => robot.alias)}
                                domain={{ x: [new Date(Date.now() - (31 * 24 * 3600000)), new Date()], y: [0, 20] }}
                            />
                        </Surface>

                        < Surface
                            style={styles.chartView}
                        >
                            <Text style={styles.viewTitle}>Trips per Hour (Past 24 Hours)</Text>

                            <VictoryChart
                                theme={VictoryTheme.material}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        voronoiDimension="x"
                                        labels={({ datum }) => datum._y}
                                        labelComponent={
                                            <VictoryTooltip
                                                constrainToVisibleArea
                                                // pointerOrientation="right"
                                                pointerLength={100}
                                                centerOffset={{ x: -25, y: -25 }}
                                            />
                                        }
                                    />
                                }
                            >

                                <VictoryAxis
                                    tickFormat={x => new Date(x).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    style={chartStyles.xAxis}
                                />

                                <VictoryAxis
                                    dependentAxis
                                    tickFormat={y => (Number.isInteger(y) ? y : null)}
                                    style={chartStyles.yAxis}
                                />

                                <VictoryBar
                                    // colorScale="qualitative"
                                    barRatio={20}
                                    // style={chartStyles.tooltip}
                                    data={analyticsData.tripsPerHour}
                                    x='date'
                                    y='numTrips'
                                    domain={{ x: [new Date(Date.now() - (24 * 3600000)), new Date()] }}
                                />

                            </VictoryChart>

                        </Surface>
                    </View>
                </>
            ) : (
                <Text>
                    Loading...
                </Text>
            )}
        </ScrollView>
    )

}


export default React.memo(AnalyticsScreen)

let chartStyles = {
    tooltip: {
        labels: {
            fontSize: 10,
            fontFamily: 'Montserrat-Regular'
        },
    },
    xAxis: {
        tickLabels: {
            angle: -45,
            transform: `translate(${Platform.OS === 'web' ? "-20, 10" : "0, 0"})`,
            fontSize: 10,
            fontFamily: 'Montserrat-Regular'
        },
        grid: { stroke: "gainsboro" },
    },
    yAxis: {
        tickLabels: {
            fontSize: 10,
            fontFamily: 'Montserrat-Regular'
        },
        grid: { stroke: "gainsboro" },
        axisLabel: {
            fontSize: 10,
            fontFamily: 'Montserrat-Regular',
            padding: 30
        },
    },
    legend: {
        labels: {
            fontSize: 10,
            fontFamily: 'Montserrat-Regular'
        },
    }
}

let styles = StyleSheet.create({
    chartView: {
        alignItems: 'center',
        padding: 40,
        margin: 20,
        elevation: 5,
        borderStyle: "solid", borderRadius: 10,
    },
    statView: {
        alignItems: 'center',
        paddingVertical: 20,
        paddingHorizontal: 40,
        margin: 20,
        elevation: 5,
        borderStyle: "solid", borderRadius: 10,
    },
    title: {
        fontSize: 50,
        fontFamily: 'Montserrat-Bold',
        textDecorationLine: 'underline',
    },
    viewTitle: {
        fontSize: 20,
        fontFamily: 'Montserrat-Bold',
        // paddingTop: 50,
        paddingBottom: 15,
    },
    statText: {
        fontSize: 40,
        fontFamily: 'Montserrat-Bold',
    },

});