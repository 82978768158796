import React, { useState } from 'react'
import {
  View, Picker, StyleSheet, SafeAreaView,
  TextInput,
  ScrollView,
} from "react-native";
import {
  Text,
  DataTable,
  IconButton,
  Button,

} from 'react-native-paper';

import DropDownPicker from 'react-native-dropdown-picker';

import ioHelper from "../Helpers/ioHelper";

import Loader from '../Components/Loader2';

const { sendDataToFleetController } = require('../Helpers/apiHelper')

function UserScreen({ client }) {

  // COMPONENTS
  const PickerWeb = ({ selected, setSelected, contents }) => {
    return (
      <>

        <Picker
          selectedValue={selected}
          style={[styles.dropdownPickerWeb, styles.bodyText]}
          itemStyle={[styles.dropdownPickerWeb, styles.bodyText]}
          onValueChange={(itemValue) =>
            setSelected(itemValue)
          }>
          {contents.map((x, i) => (
            <Picker.Item key={`${i}`} label={x.label} value={x.value} />
          ))}
        </Picker>

      </>

    )
  }

  const PickerMobile = ({
    open, value, placeholder, items, setOpen, setValue, setItems,
  }) => {
    return (
      <DropDownPicker
        itemSeparator={true}
        listMode="MODAL"
        bottomOffset={300}
        open={open}
        value={value}
        placeholder={placeholder}
        items={items}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        style={styles.dropdownPicker}
        textStyle={
          styles.bodyText
        }
        labelStyle={
          styles.bodyText
        }
        modalContentContainerStyle={{
          backgroundColor: "#777",
        }}
        itemSeparatorStyle={{
          color: "#fff",
          width: "100%",
        }}
        labelProps={{
          value: "VALUE",
        }}
      // theme="DARK"
      />
    )
  }

  const Edit = ({ rowIndex, }) => {
    return (
      <DataTable.Cell>
        <IconButton onPress={() => handleEdit(rowIndex)}
          icon="pencil"
          style={{
            // height: 10, width: 10, margin: 2, 
            // flex: 1, flexDirection: 'row',
          }} />
      </DataTable.Cell>
    )
  }


  const Status = ({ row, rowIndex, }) => {


    // METHODS
    async function handleApprove(rowIndex) {
      const id = userData[rowIndex]._id;
      const update = {
        status: {
          dispatcher: "approved"
        }
      }
      const postData = {
        command: "updateClientData",
        data: {
          _id: id,
          update: update,
        },
      }
      sendDataToFleetController('clients', postData).then(doc => {
        console.log("Approved", doc)
        handleRefresh();
      }).catch(e => {
        console.error(e)
      });
    }

    async function handleDecline(rowIndex) {
      const id = userData[rowIndex]._id;
      const update = {
        status: {
          dispatcher: "declined"
        }
      }
      const postData = {
        command: "updateClientData",
        data: {
          _id: id,
          update: update,
        },
      }
      sendDataToFleetController('clients', postData).then(doc => {
        console.log("Declined", doc);
        handleRefresh();
      }).catch(e => {
        console.error(e)
      });
    }

    return (
      <DataTable.Cell style={{
        // borderWidth: 1, borderColor: "blue", borderStyle: "solid", 
      }}>
        {row.status?.dispatcher === "approved" ? (
          <Text style={{
            color: "green"
          }}>
            Approved
          </Text>
        ) : (
          (row.status?.dispatcher === "declined" |
            [
              "collector"
            ].includes(row.accessLevel)) ? (
            <Text style={{
              color: "red"
            }}>
              Declined
            </Text>
          ) : (





            <View style={{
              // borderWidth: 1, borderColor: "#000", borderStyle: "solid",
              // height: 90, width: 100, 
            }}>

              <IconButton onPress={() => handleApprove(rowIndex)}
                icon="account-check-outline"
                style={{
                  // height: 10, width: 10, margin: 2, 
                  // flex: 1, flexDirection: 'row',
                }} />

              <IconButton onPress={() => handleDecline(rowIndex)}
                icon="account-remove-outline"
                style={{
                  // height: 10, width: 10, margin: 2, 
                  // flex: 1, flexDirection: 'row',
                }} />

            </View>



          )
        )}
      </DataTable.Cell>
    )
  }


  const ToolBar = ({ queryData, setQueryData, }) => {

    // STATES
    const [searchString, setSearchString] = React.useState(queryData.username);
    const [selectedRole, setSelectedRole] = React.useState(queryData.role);
    const [openRolesDropdown, setOpenRolesDropdown] = React.useState(false);
    const [roles, setRoles] = React.useState([
      'all', 'collector', 'dispatcher', 'manager',
    ])

    const [selectedStatus, setSelectedStatus] = React.useState(queryData.dispatchStatus);
    const [openStatusDropdown, setOpenStatusDropdown] = React.useState(false);
    const [statuses, setStatuses] = React.useState([
     'all', 'pending', 'approved', 'declined',
    ])


    // METHODS 
    function handleQuery() {
      console.log({ selectedStatus })
      setQueryData({
        username: searchString,
        role: selectedRole === 'all' ? '' : selectedRole,
        dispatchStatus: selectedStatus === 'all' ? '' : selectedStatus,
      })
      handleRefresh();
    }


    return (
      <View style={styles.childView}>



        <IconButton onPress={handleRefresh}
          icon="refresh"
          style={{
            // height: 10, width: 10, margin: 2, 
          }} />



        <TextInput
          style={styles.input}
          value={searchString}
          onChangeText={setSearchString}
          selectTextOnFocus
          placeholder="Enter Username"
        />


        <View style={styles.dropdownContainer}>
          {deviceType === 'web' ? (
            <PickerWeb
              selected={selectedRole}
              setSelected={setSelectedRole}
              contents={roles.map(department => {
                return { label: department, value: department }
              })}

            />
          ) : (
            <PickerMobile
              open={openRolesDropdown}
              setOpen={setOpenRolesDropdown}
              value={selectedRole}
              setValue={setSelectedRole}
              placeholder={`Select Role`}
              items={roles.map(department => {
                return { label: department, value: department }
              })}
              setItems={setRoles}

            />
          )}
        </View>


        <View style={[styles.dropdownContainer, {
          marginLeft: 8,
        }]}>
          {deviceType === 'web' ? (
            <PickerWeb
              selected={selectedStatus}
              setSelected={setSelectedStatus}
              contents={statuses.map(status => {
                return { label: status, value: status }
              })}

            />
          ) : (
            <PickerMobile
              open={openStatusDropdown}
              setOpen={setOpenStatusDropdown}
              value={selectedStatus}
              setValue={setSelectedStatus}
              placeholder={`Select Status`}
              items={statuses.map(status => {
                return { label: status, value: status }
              })}
              setItems={setStatuses}

            />
          )}
        </View>


        <IconButton
          icon='account-search-outline'
          onPress={handleQuery}
        />


        {/* <Button
          style={{
            marginLeft: 40,
          }}
          mode="contained">
          NEW USER
        </Button> */}





      </View>
    )
  }


  // STATES
  const [loadNewData, setLoadNewData] = useState(true);


  // Pickers
  const [openDepartmentsDropdown, setOpenDepartmentsDropdown] = useState(false);  // For the Departments dropdown list 
  const [departmentValue, setDepartmentValue] = useState(null);
  const [departmentDropDownListItems, setDepartmentDropDownListItems] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  // Get the list of departments from a user's clientId
  React.useEffect(() => {


    async function getDepartmentsList() {
      const clientId = await ioHelper.retrieveData("client_id");
      const postData = {
        command: "getDepartments",
        data: {
          clientId: clientId,
        },
      };

      const { data } = await sendDataToFleetController('robots', postData);
      if (data?.departments) {
        setDepartmentDropDownListItems(data.departments.map(x => {
          return { label: x, value: x }
        }))
      }
    }

    getDepartmentsList();

  }, [])


  // The dialog popup 
  const [dialogContent, setDialogContent] = React.useState({
    title: '',
    data: {},
  });
  const [visible, setVisible] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');

  // Update dialog content
  React.useEffect(() => {
    console.log('-------EFFECT-------')
    setDialogContent({
      ...dialogContent,
      data: departmentValue || selectedDepartment,
    })
  }, [departmentValue, selectedDepartment])



  
  // Get client's user data from fleet manager
  const [userData, setUserData] = React.useState([])
  const [queryData, setQueryData] = useState({
    username: '',
    role: 'dispatcher',
    dispatchStatus: '',
  });
  const numberOfItemsPerPageList = [10, 20, 50, 100];
  const [totalDocs, setTotalDocs] = React.useState(0);

  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, totalDocs);

  const [loading, setLoading] = React.useState(false)

  // On page change 
  React.useEffect(() => {
    handleRefresh();
  }, [numberOfItemsPerPage, totalDocs]);

  // Get table data from db 
  React.useEffect(() => {

    async function getAllUsersForClient() {

      setLoading(true);

      const clientId = await ioHelper.retrieveData("client_id")
      const postData = {
        "command": "getAllUsersForClient",
        "data": {
          "clientId": clientId,
          "query": queryData,
          "page": page,
          "size": numberOfItemsPerPage
        }
      };

      let { totalDocs: total, docs: data } = await sendDataToFleetController('clients', postData);

      setUserData(data);
      setTotalDocs(total)
      setLoadNewData(0);
      setLoading(false);

      function sortByKey(array, key) {
        return array.sort(function (a, b) {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      }



    }

    getAllUsersForClient();

  }, [loadNewData, page, ])





  // Get the device type
  const [deviceType, setDeviceType] = useState(null);
  React.useEffect(() => {


    async function retrieveDType() {
      const dType = await ioHelper.retrieveData("device_type");
      setDeviceType(dType);
      console.log("DEVICETYPE:", dType)
    }

    retrieveDType();

  }, [deviceType, setDeviceType]);



  // METHODS

  async function handleEdit(rowIndex) {
    console.log("Edit", userData[rowIndex]._id);
    showEditDialog();
  }

  const hideEditDialog = () => {
    setVisible(false);
    setErrorText('')
  }

  const showEditDialog = () => setVisible(true);

  async function displayEditDialog(field, data) {
    console.log("EDIT", field, data[field]);

    setDialogContent({
      ...dialogContent,
      title: `Edit ${field}`,
      data: data[field],
    })

    showEditDialog()
  }

  function submitEditData() {

    console.log("SUBMIT", dialogContent)
    console.log(userData)
    const postData = {
      command: "updateClientData",
      data: {
        _id: userData._id,
        update: {}
      }
    }
    if (dialogContent.title.split(" ")[1] === "department") {
      postData.data.update['department'] = dialogContent.data;
    }
    if (dialogContent.title.split(" ")[1] === "pin") {
      postData.data.update['pin'] = dialogContent.data;
    }

    sendDataToFleetController('clients', postData).then(async doc => {
      console.log("UPDATED DATA", doc)
      if (doc.status === 200) {
        await ioHelper.storeData("department", doc.data.department)
        hideEditDialog();
      } else if (doc.status === 201) {
        setErrorText("Sorry, that PIN is already taken. Please try a different PIN.");
      }
    }).catch(e => {
      console.error(e)
    })

  }


  const handleRefresh = React.useCallback(() => {
    setLoadNewData(Math.random());
  }, [setLoadNewData])


  return (
    <SafeAreaView style={{
      justifyContent: 'flex-start',
      display: 'flex', flexDirection: 'column', flex: 1,
      backgroundColor: '#fff'
    }}>
      <ScrollView style={styles.view}>


        <ToolBar queryData={queryData} setQueryData={setQueryData} />


        <DataTable>


          <DataTable.Pagination
            page={page}
            numberOfPages={Math.ceil(totalDocs / numberOfItemsPerPage)}
            onPageChange={page => setPage(page)}
            label={`${from + 1}-${to} of ${totalDocs}`}
            showFastPaginationControls
            numberOfItemsPerPageList={numberOfItemsPerPageList}
            numberOfItemsPerPage={numberOfItemsPerPage}
            onItemsPerPageChange={onItemsPerPageChange}
            selectPageDropdownLabel={'Rows per page'}
          />


          <DataTable.Header>

            {["Username", "Role", "Department", "Clock No.", "Status",].map((title, index) => {
              return (
                <DataTable.Title key={`h-${index}`} >
                  {title}
                </DataTable.Title>
              )
            })}

          </DataTable.Header>


          {
            loading ? (
              <View style={
                {
                  margin: 20,

                }
              }>

                <Loader />
              </View>
            ) : (
              userData?.length > 0 ? (
                userData.map((row, index) => {
                  return (
                    <DataTable.Row style={{
                      height: 80,
                    }} key={`r-${index}`}>
                      <DataTable.Cell>{row.alias}</DataTable.Cell>

                      <DataTable.Cell>{row.accessLevel}</DataTable.Cell>
                      <DataTable.Cell>{row.department}</DataTable.Cell>
                      <DataTable.Cell>{row.employeeId}</DataTable.Cell>

                      <Status row={row} rowIndex={index} setLoadNewData={setLoadNewData} />
                      {/* <Edit rowIndex={index} /> */}
                    </DataTable.Row>
                  )
                })
              ) : (
                <Text>
                  No result
                </Text>
              )
            )
          }






        </DataTable>


      </ScrollView >



    </SafeAreaView >
  )
}


const styles = StyleSheet.create({
  childSurface: {
    borderWidth: 1, borderColor: "red", borderStyle: "solid", borderRadius: 10,
    flex: 1, flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: "#ddd",
    elevation: 0,
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginHorizontal: 10,
    marginVertical: 10,
  },
  dropdownPicker: {
    backgroundColor: "#ddd",
    // borderColor: "#111", width: '100%'
  },
  dropdownContainer: {
    borderWidth: 0,
    borderColor: "green",
    borderStyle: "solid",
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  view: {
    margin: 10,
  },
  childView: {
    flex: 1, flexDirection: 'row',
    borderWidth: 0, borderColor: 'black',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5,
    minHeight: 40,
  },
  headerText: {
    margin: 10,
    fontWeight: 'bold', fontSize: 18,
  },
  text: {
    fontSize: 16,
    margin: 10,
  },
  input: {
    flex: 1.2,
    height: 40,
    borderWidth: 3, borderRadius: 8, borderColor: "#aaa",
    marginRight: 5,
    padding: 10,
    minWidth: 100,
  },
  dropdownPicker: {
    maxHeight: 40,
    borderWidth: 3, borderRadius: 8, borderColor: "#aaa",
  },
  dropdownPickerWeb: {
    height: 40,
    maxHeight: 40, flex: 1,
    borderWidth: 3, borderRadius: 8, borderColor: "#aaa",
  }
});


export default UserScreen
