import React from 'react'
import { View, SafeAreaView, Image, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, IconButton, Text, TextInput, Paragraph, Dialog, Portal, Surface } from 'react-native-paper';

import RobotsContext from '../Context/robotsContext'
import ioHelper from '../Helpers/ioHelper';


const styles = StyleSheet.create({
    rootSafeArea: {
        flex: 1,
        backgroundColor: '#fff'
    },
    rootView: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",

        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',

        padding: 8,
    },
    surfaceView: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        borderRadius: 12,

        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',

        maxWidth: 300,
        minWidth: 200,
        margin: 8,
        padding: 8,

        elevation: 8,
    },



    bodyText: {
        color: "#ccc",
        fontSize: 14,
    },
    headingText: {
        color: "#ccc",
        fontSize: 14,
        textTransform: "uppercase",
    },

})


function MonitorScreen({ client }) {

    const isMounted = React.useRef(null);
    const reRun = React.useRef(null);

    const [statusDocuments, setStatusDocuments] = React.useState([])
    const [robotIds, setRobotIds] = React.useState([]);

    const [robotStates, setRobotStates] = React.useState({});

    // Listen to statusDoc
    React.useEffect(() => {

        isMounted.current = true;

        const initSocketListeners = async () => {

            initStatusDocuments();

            async function initStatusDocuments() {

                const clientId = await ioHelper.retrieveData("client_id");

                const rootCollectionIds = (
                    await client.service("robots").find({
                        query: {
                            clientId: clientId,
                            $select: {
                                rootCollection: 1,
                            }
                        }
                    })
                ).data.map(x => x.rootCollection);


                const statusDocumentIds = (
                    await client.service("collections").find({
                        query: {
                            _id: {
                                $in: rootCollectionIds
                            },
                            $select: {
                                "documents.statusDocument": 1,
                            }
                        }
                    })
                ).data.filter(x => x.documents.statusDocument !== undefined).map(x => x.documents.statusDocument.split(".")[2]);


                const statusDocs = (await client.service('documents').find({
                    query: {
                        _id: {
                            $in: statusDocumentIds,
                        },
                        $select: {
                            "fieldValues": -1,
                            "path": -1,
                        }
                    }
                })).data;

                for (let i = 0, n = statusDocs.length; i < n; i++) {
                    const document = statusDocs[i];

                    // Find the robot doc
                    const robotDoc = await client.service("robots").get(document.path.split('.')[0]);
                    const robotId = robotDoc.robotId;
                    robotStates[`${robotId}`] = {
                        statusDoc: document,
                        robotDoc: robotDoc,
                    }

                }

                setRobotStates({
                    ...robotStates
                });

                console.log("UPDATE ROBOT STATES", robotStates)
                const ids = Object.keys(robotStates)
                const states = Object.values(robotStates)
                setRobotIds(ids)
                setStatusDocuments(states)


            }

            try {
                client.service("documents").removeAllListeners("patched");
                client.service("documents").on("patched", async document => {
                    // console.log('PATCH:', document._id, Date.now());

                    const statusUpdate = document.fieldValues.type === 'status'

                    if (statusUpdate) {
                        try {
                            // Find the robot doc
                            const robotDoc = await client.service("robots").get(document.path.split('.')[0]);
                            const robotId = robotDoc.robotId;
                            robotStates[`${robotId}`] = {
                                statusDoc: document,
                                robotDoc: robotDoc,
                            }
                            setRobotStates({
                                ...robotStates
                            });

                            // console.log("UPDATE ROBOT STATES", robotStates)
                            const ids = Object.keys(robotStates);
                            const states = Object.values(robotStates);
                            setRobotIds(ids);
                            setStatusDocuments(states);

                        } catch (e) {
                            console.log("Caught exception @NavigationEffect2: INIT LISTENERS")
                            console.error(e);
                        }
                    }

                });


            } catch (e) {
                console.error("Caught @initSocketListeners: ", e);

            }



        }

        if (isMounted.current) initSocketListeners();

        reRun.current = true;

        return () => {
            // executed when unmount
            isMounted.current = false;
            client.service("documents").removeAllListeners("patched");
        }

    }, [reRun.current]);


    return (
        <ScrollView
            horizontal={true}
        >

            <SafeAreaView style={styles.rootSafeArea}>

                <View style={styles.rootView}
                >

                    {
                        statusDocuments.map((state, i) => {
                            return (
                                <Surface
                                    key={`${robotIds[i]}`}
                                    style={styles.surfaceView}
                                >

                                    <Paragraph>
                                        <Text>
                                            {state.robotDoc.robotId} : {state.statusDoc.fieldValues?.data.alias}
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            Online = {state.statusDoc.fieldValues?.data.connected?.toString()}
                                            {"\n\n"}
                                        </Text>
                                        <Text>
                                            Battery = {(+(state.statusDoc.fieldValues?.data.battery?.toString())).toFixed(0)}
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            Charging = {((state.statusDoc.fieldValues?.data.charging?.toString()))}
                                            {"\n\n"}
                                        </Text>
                                        <Text>
                                            Position
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            x = {(+(state.statusDoc.fieldValues?.data.position?.x.toString())).toFixed(3)}
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            y = {(+(state.statusDoc.fieldValues?.data.position?.y.toString())).toFixed(3)}
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            Moving = {(state.statusDoc.fieldValues?.data.moving?.toString())}
                                            {"\n\n"}
                                        </Text>
                                        <Text>
                                            Task {(state.statusDoc.fieldValues?.data.taskShortId)} {(state.statusDoc.fieldValues?.data.taskStatus)}
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            Going to = {(state.statusDoc.fieldValues?.data.to)}
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            From = {(state.statusDoc.fieldValues?.data.from)}
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            Status = {(state.statusDoc.fieldValues?.data.status)}
                                            {"\n\n"}
                                        </Text>
                                        <Text>
                                            Last Updated
                                            {"\n"}
                                        </Text>
                                        <Text>
                                            {(state.statusDoc.updatedAt)}
                                        </Text>

                                    </Paragraph>

                                </Surface>
                            )
                        })
                    }



                </View>


            </SafeAreaView>
        </ScrollView>

    )
}

export default MonitorScreen
