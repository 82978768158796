import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';

import 'react-native-gesture-handler';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import SplashScreen from './Screen/SplashScreen';
import LoginScreen from './Screen/LoginScreen';
import RegisterScreen from './Screen/RegisterScreen';
import DrawerNavigationRoutes from './Screen/DrawerNavigationRoutes';
import ChatScreen from './Screen/LiveChat';


import {
  configureFonts,
  DefaultTheme,
  Provider as PaperProvider
} from 'react-native-paper';

import ioHelper from "./Screen/Helpers/ioHelper";

import { SocketProvider } from './Screen/Context/socketContext'
import socketHelper from './Screen/Helpers/socketHelper';

import { sendDataToFleetController } from './Screen/Helpers/apiHelper';

let { server } = require('./Constants');

const socket = io(server, {
  // path: default is '/socket.io/',
  transports: ['websocket'],
  forceNew: true,
  timeout: 10000,
});
const client = feathers();

client.configure(socketio(socket));
client.configure(authentication({
  storage: AsyncStorage
}));








const Stack = createStackNavigator();

const Auth = () => {
  // Stack Navigator for Login and Sign up Screen
  return (
    <Stack.Navigator initialRouteName="LoginScreen">
      <Stack.Screen
        name="LoginScreen"
        options={{ headerShown: false }}
      >
        {props => {
          return <LoginScreen {...props} client={client} />
        }}
      </Stack.Screen>
      <Stack.Screen
        name="RegisterScreen"
        component={RegisterScreen}
        options={{
          title: 'Register', //Set Header Title
          headerStyle: {
            backgroundColor: '#707070', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />
    </Stack.Navigator>
  );
};


const ChatScreenStack = ({ navigation, client }) => {
  return (
    <Stack.Navigator
      initialRouteName="ChatScreen"
    >


      <Stack.Screen
        name="ChatScreen"
        options={{
          headerShown: false,
        }}
      >
        {props => {
          return <ChatScreen {...props} navigation={navigation} client={client} />
        }}
      </Stack.Screen>


    </Stack.Navigator>
  );
};



export default function App() {


  const [connectionState, setConnectionState] = React.useState('');
  const [socketUser, setSocketUser] = React.useState('');
  const [socketPass, setSocketPass] = React.useState('');



  // Socket connection event handlers 
  React.useEffect(() => {


    client.io.on('connect', async () => {
      console.log("SOCKET CONNECT");
      setConnectionState("connect")



      const alias = await ioHelper.retrieveData("user_name");
      const password = await ioHelper.retrieveData("user_password");

      try {
        const postData = {
          command: "getClientInfo",
          data: {
            alias: alias,
            password: password,
          },
        };

        const { data, status, message } = await sendDataToFleetController('clients', postData);
        if (status == 200) {
          const { socketUser, socketPass, clientId, pin, employeeId } = data;
          ioHelper.storeData("user_pin", pin);
          ioHelper.storeData("employee_id", employeeId);
          const deviceId = await ioHelper.retrieveData("device_id");
          if (await socketHelper.socketLogin(client, socketUser, socketPass, clientId, deviceId) == 0) {
            console.log("Logged in")
          }
        } else {
          throw {
            msg: message
          }
        }


      } catch (e) {
        console.error("Socket connection exception:", e);
      }

    });

    client.io.on('disconnect', (reason) => {
      console.log("SOCKET DISCONNECT", reason);
      setConnectionState("disconnect");
    });

    client.io.on('reconnect', (attemptNo) => {
      // Successful reconnection 
      console.log("SOCKET RECONNECT:", attemptNo);
      setConnectionState("reconnect");
    });

    client.io.on('reconnect_attempt', (attemptNo) => {
      console.log("SOCKET RECONNECT_ATTEMPT:", attemptNo);
      setConnectionState("reconnect_attempt");
    });

    client.io.on('reconnect_error', (err) => {
      console.log("SOCKET RECONNECT_ERR");
      setConnectionState("reconnect_error")
    });

    client.io.on('reconnect_failed', () => {
      console.log("SOCKET RECONNECT_FAILED");
      setConnectionState("reconnect_failed")
    });


  }, []);


  return (
    <SocketProvider value={{
      connection: connectionState,
      socketUser: socketUser,
      socketPass: socketPass
    }} >



      <PaperProvider theme={theme}>
        <NavigationContainer>
          <Stack.Navigator
            initialRouteName="SplashScreen"
          >


            {/* SplashScreen which will come once for 5 Seconds */}
            <Stack.Screen
              name="SplashScreen"
              component={SplashScreen}
              // Hiding header for Splash Screen
              options={{ headerShown: false }}
            />

            {/* Auth Navigator: Include Login and Signup */}
            <Stack.Screen
              name="Auth"
              component={Auth}
              options={{ headerShown: false }}
            />

            {/* Navigation Drawer as a landing page */}
            <Stack.Screen
              name="DrawerNavigationRoutes"
              // Hiding header for Navigation Drawer
              options={{ headerShown: false }}
            >
              {props => {
                return <DrawerNavigationRoutes {...props} client={client} />
              }}
            </Stack.Screen>


            <Stack.Screen
              name="LiveChat"
            >
              {props => {
                return <ChatScreenStack {...props} client={client} />
              }}

            </Stack.Screen>



          </Stack.Navigator>
        </NavigationContainer>

      </PaperProvider>


    </SocketProvider>

  );
}


const theme = {
  ...DefaultTheme,
  fonts: configureFonts(fontConfig),
};


const fontConfig = {
  web: {
    regular: {
      fontFamily: 'sans-serif',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'sans-serif-medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'sans-serif-light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'sans-serif-thin',
      fontWeight: 'normal',
    },
  },
  ios: {
    regular: {
      fontFamily: 'sans-serif',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'sans-serif-medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'sans-serif-light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'sans-serif-thin',
      fontWeight: 'normal',
    },
  },
  android: {
    regular: {
      fontFamily: 'sans-serif',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'sans-serif-medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'sans-serif-light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'sans-serif-thin',
      fontWeight: 'normal',
    },
  }
};