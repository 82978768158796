import React, { useState } from 'react';
import {
  Text, TextInput,
  TouchableOpacity,
  View, ScrollView, StyleSheet,
  KeyboardAvoidingView,
  FlatList
}
  from 'react-native';

import { Snackbar } from 'react-native-paper';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import Loader from './Loader2';

const { sendDataToQueueHandler, sendDataToFleetController } = require('../Helpers/apiHelper');

import ioHelper from "../Helpers/ioHelper";


import RobotsContext from '../Context/robotsContext'


const styles1 = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
  group3: {
    width: 672,
    height: 740,
    marginLeft: 36,
    marginTop: 52
  },
  image: {
    width: 672,
    height: 800,
    position: "absolute",
    left: 0,
    top: 0
  },
  image_imageStyle: {},
  box2Pressable: {
    height: 110,
    width: 110,
    borderRadius: 100,
    marginTop: 200,
    marginLeft: 38,

    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  box2PressableText: {
    color: "rgba(0,0,0,1)",
    fontSize: 45,
    fontFamily: "Montserrat-Bold"
  },
  box4Pressable: {
    height: 110,
    width: 110,
    borderRadius: 100,

    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  box4PressableText: {
    color: "rgba(0,0,0,1)",
    fontSize: 45,
    fontFamily: "Montserrat-Bold"
  },
  box3Pressable: {
    height: 110,
    width: 110,
    borderRadius: 100,
    marginLeft: 350,

    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  box3PressableText: {
    color: "rgba(0,0,0,1)",
    fontSize: 45,
    fontFamily: "Montserrat-Bold"
  },
  materialButtonVioletRow: {
    height: 122,
    flexDirection: "row",
    marginTop: 25,
    marginLeft: 38,
    marginRight: 1
  },
  box5Pressable: {
    height: 110,
    width: 110,
    borderRadius: 100,
    marginTop: 10,
    marginLeft: 500,

    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  box5PressableText: {
    color: "rgba(0,0,0,1)",
    fontSize: 45,
    fontFamily: "Montserrat-Bold"
  },
  boxPressable: {
    height: 310,
    width: 250,
    // position: "absolute",
    // left: 500,
    // top: 200,
    borderRadius: 20,
    margin: 10,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16,
  },
  boxPressableText: {
    color: "rgba(0,0,0,1)",
    fontSize: 45,
    fontFamily: "Montserrat-Bold"
  },
  imageStack: {
    width: 673,
    height: 947,
    marginLeft: -19
  },
  group3Filler: {
    flex: 1,
    flexDirection: "row"
  },
  group4: {
    width: 640,
    height: 740,
    // alignSelf: "center",
    // marginRight: 36,
    // marginBottom: 100
  },
  textWrapper1: {
    position: "absolute",
    left: 29,
    top: 10,
    right: 551,
    height: 44,
    alignItems: "center",
    justifyContent: "center"
  },
  scriptsLoaded1: {
    fontFamily: "Montserrat-Bold",
    fontSize: 30,
    color: "rgba(0,0,0,1)",
    backgroundColor: "transparent",
    lineHeight: 32
  },
  rect1: {
    top: 0,
    left: 0,
    width: 615,
    height: 771,
    position: "absolute",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 20,
    shadowColor: "rgba(92,84,84,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 150,
    shadowOpacity: 0.21,
    shadowRadius: 50,
    borderWidth: 1,
    borderColor: "rgba(238,236,236,1)"
  },
  textWrapper2: {
    height: 44,
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 16,
    marginLeft: 33,
    marginRight: 286
  },
  scriptsLoaded2: {
    fontFamily: "Montserrat-Bold",
    fontSize: 30,
    color: "rgba(0,0,0,1)",
    backgroundColor: "transparent",
    lineHeight: 32
  },
  materialRightIconTextbox18: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 13,
    marginLeft: 29
  },
  materialRightIconTextbox17: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 11,
    marginLeft: 29
  },
  materialRightIconTextbox16: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 9,
    marginLeft: 29
  },
  materialRightIconTextbox15: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 9,
    marginLeft: 29
  },
  materialRightIconTextbox14: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 10,
    marginLeft: 29
  },
  materialRightIconTextbox13: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 9,
    marginLeft: 29
  },
  materialRightIconTextbox12: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 10,
    marginLeft: 29
  },
  materialRightIconTextbox11: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 11,
    marginLeft: 29
  },
  materialRightIconTextbox10: {
    height: 50,
    width: 547,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 9,
    marginLeft: 29
  },
  textWrapper1Stack: {
    top: 0,
    left: 0,
    height: 771,
    position: "absolute",
    right: 0
  },
  materialRightIconTextbox1: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 431,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox2: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 492,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox3: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 551,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox4: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 371,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox5: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 312,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox6: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 252,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox7: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 193,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox8: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 134,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  materialRightIconTextbox9: {
    height: 50,
    width: 547,
    position: "absolute",
    left: 29,
    top: 73,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  textWrapper1StackStack: {
    height: 690,
    marginRight: -2,
    // justifyContent: "center"
  },
  confirmPressable: {
    height: 67,
    width: 292,
    borderRadius: 20,
    // marginTop: 14,
    alignSelf: "center",
    // marginLeft: 321,

    backgroundColor: "#2196F3",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  confirmPressableText: {
    color: "#fff",
    fontSize: 30,
    fontFamily: "Montserrat-SemiBold"
  },
  bodyText: {
    color: '#333',
    fontSize: 18,
  },
});

const styles2 = StyleSheet.create({
  container: {
    // flex: 1,
    // alignContent: "center",
    // justifyContent: "center"
    alignSelf: "center"
  },
  group4: {
    width: 1163,
    height: 749,
    marginTop: 80,
    // borderColor: "#000", borderWidth: 1,
  },
  compartmentPressable: {
    height: 123,
    width: 472,
    borderRadius: 100,

    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  compartmentPressableText: {
    color: "rgba(0,0,0,1)",
    fontSize: 45,
    fontFamily: "Montserrat-Bold"
  },
  selectWard: {
    fontFamily: "Montserrat-Bold",
    color: "#121212",
    height: 41,
    width: 401,
    textAlign: "center",
    fontSize: 30,
    marginTop: 45,
    marginLeft: 36
  },
  departmentsPressable1: {
    height: 91,
    width: 89,
    borderRadius: 100,
    marginTop: 13,
    marginLeft: 191,

    backgroundColor: "#3F51B5",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2
  },
  departmentsPressable2: {
    // height: 91,
    // width: 89,
    borderRadius: 100,
    marginTop: 13,
    paddingHorizontal: 30,
    paddingVertical: 10,
    // marginLeft: 191,

    backgroundColor: "#3F51B5",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2
  },
  departmentsPressableText: {
    color: "#fff",
    fontFamily: "Montserrat-SemiBold",
    fontSize: 49,
  },
  materialButtonPink1Column: {
    width: 472,
    // borderColor: "#00f", borderWidth: 1,
  },
  materialButtonPink1ColumnFiller: {
    flex: 1
  },
  group2: {
    width: 501,
    height: 281,
    marginTop: 45,
  },
  rect2: {
    width: 499,
    height: 280,
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 20,
    shadowColor: "rgba(92,84,84,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 150,
    shadowOpacity: 0.21,
    shadowRadius: 50,
    borderWidth: 1,
    borderColor: "rgba(238,236,236,1)"
  },
  inputContainer: {
    height: 153,
    width: 439,
    marginTop: 17,
    marginLeft: 44,
    backgroundColor: "transparent"
  },
  addScriptId: {
    fontFamily: "Montserrat-Bold",
    fontSize: 30,
    textAlign: "left",
    color: "#000",
    opacity: 0.6,
    paddingTop: 16
  },
  helper: {
    fontSize: 12,
    textAlign: "left",
    color: "#000",
    opacity: 0.6,
    paddingTop: 8
  },
  inputStyle: {
    borderBottomWidth: 1,
    borderColor: "rgba(141,141,141,1)",
    color: "#000",
    fontFamily: "Montserrat-Bold",
    fontSize: 20,
    alignSelf: "stretch",
    lineHeight: 16,
    paddingTop: 8,
    // flex: 1,
    paddingBottom: 0,
    width: 435,
    left: 0,
    top: 30,
    height: 50
  },
  addPressable: {
    height: 50,
    width: 133,
    marginTop: 37,
    marginLeft: 164,

    backgroundColor: "#2196F3",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  addPressableText: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Montserrat-Bold"
  },
  materialButtonPink1ColumnColumn: {
    width: 501,
    // marginTop: 19,
    // borderColor: "#0f0", borderWidth: 1,
  },
  materialButtonPink1ColumnColumnFiller: {
    flex: 1,
    flexDirection: "row"
  },
  group: {
    width: 610,
    height: 643,
    alignSelf: "flex-end",
    marginBottom: 106
  },
  textWrapper1: {
    position: "absolute",
    left: 29,
    top: 9,
    right: 566,
    height: 38,
    alignItems: "center",
    justifyContent: "center"
  },
  scriptsLoaded1: {
    fontFamily: "Montserrat-Bold",
    fontSize: 30,
    color: "rgba(0,0,0,1)",
    backgroundColor: "transparent",
    lineHeight: 32
  },
  rect1: {
    top: 0,
    left: 0,
    width: 628,
    height: 639,
    position: "absolute",
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 20,
    shadowColor: "rgba(92,84,84,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 150,
    shadowOpacity: 0.21,
    shadowRadius: 50,
    borderWidth: 1,
    borderColor: "rgba(238,236,236,1)"
  },
  textWrapper2: {
    height: 38,
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 20,
    marginLeft: 29,
    marginRight: 323
  },
  scriptsLoaded2: {
    fontFamily: "Montserrat-Bold",
    fontSize: 30,
    color: "rgba(0,0,0,1)",
    backgroundColor: "transparent",
    lineHeight: 32
  },
  materialRightIconTextbox18: {
    height: 50,
    width: 568,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 26,
    marginLeft: 29
  },
  materialRightIconTextbox17: {
    height: 50,
    width: 568,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 11,
    marginLeft: 29
  },
  materialRightIconTextbox16: {
    height: 50,
    width: 568,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 9,
    marginLeft: 29
  },
  materialRightIconTextbox15: {
    height: 50,
    width: 568,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 9,
    marginLeft: 29
  },
  materialRightIconTextbox14: {
    height: 50,
    width: 568,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 10,
    marginLeft: 29
  },
  textWrapper1Stack: {
    // borderColor: "#0f0", borderWidth: 1,
    // top: 0,
    // left: 0,
    height: 639,
    // position: "absolute",
    // right: 0
  },
  materialRightIconTextbox9: {
    height: 50,
    width: 568,
    position: "absolute",
    left: 29,
    top: 73,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5
  },
  textWrapper1StackStack: {
    height: 639,
    marginRight: -18
  },
  confirmPressable: {
    height: 67,
    width: 292,
    borderRadius: 20,
    marginTop: 44,
    marginLeft: 168,

    backgroundColor: "#2196F3",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  confirmPressableText: {
    color: "#fff",
    fontSize: 30,
    fontFamily: "Montserrat-SemiBold"
  },
  materialButtonPink1ColumnColumnRow: {
    height: 749,
    flexDirection: "row",
    marginRight: 1,
    // borderColor: "#f00", borderWidth: 1,
  },
  itemContainer: {
    flexDirection: "row",
    // shadowOpacity: 0.33,

    height: 50,
    width: 568,
    backgroundColor: "rgba(255,255,255,1)",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 30,
    shadowOpacity: 0.11,
    shadowRadius: 10,
    borderWidth: 1,
    borderColor: "rgba(236,234,234,1)",
    borderRadius: 5,
    marginTop: 15,
    marginBottom: 5,
    marginLeft: 29
  },
  itemStyle: {
    color: "#000",
    paddingRight: 16,
    paddingLeft: 16,
    fontSize: 22,
    fontFamily: "Montserrat-Bold",
    lineHeight: 16,
    paddingTop: 14,
    paddingBottom: 8,
    textAlign: "left",
    left: 0,
    top: 8,
    height: 41,
    width: 210
  },
  editPressable: {
    height: 30,
    width: 30,
    position: "absolute",
    top: 9,
    borderRadius: 100,
    right: 11,

    backgroundColor: "#3F51B5",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2
  },
  editPressableText: {
    color: "#fff",
    fontSize: 24
  },
  deletePressable: {
    height: 30,
    width: 30,
    position: "absolute",
    top: 9,
    borderRadius: 100,
    right: 54,

    backgroundColor: "rgba(255,31,46,1)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2
  },
  deletePressableText: {
    color: "#fff",
    fontSize: 24
  }
})

const styles3 = StyleSheet.create({
  container: {
    flex: 1,

  },
  group2: {
    width: 1202,
    justifyContent: "center",
    flex: 1,
    marginBottom: 50,
    marginTop: 80,
    marginLeft: 82
  },
  group3: {
    width: 1261,
    height: 632,
    alignSelf: "center"
  },
  rect: {
    // borderWidth: 1, borderColor: 'blue', borderStyle: 'solid', 
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 20,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 150,
    shadowOpacity: 0.21,
    shadowRadius: 50,
    flexDirection: "column",
    flex: 1
  },
  selectAWard: {
    // color: "#fff",
    // height: 100, width: 1000,
    textAlign: "center",
    // borderColor:"red", borderWidth:1,
    marginTop: 20,
    fontSize: 50,
    fontFamily: "Montserrat-Bold"
  },
  departmentPressable: {
    height: 96,
    width: 250,
    borderRadius: 20,
    marginHorizontal: 10,
    marginBottom: 20,

    backgroundColor: "rgba(155,155,155,1)",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  departmentPressableText: {
    color: "#fff",
    fontSize: 30,
    fontFamily: "Montserrat-Bold"
  },
  wardRow: {
    // borderWidth: 1, borderColor: 'black', borderStyle: 'solid', 

    height: 96,

    // maxWidth: 1200,
    flexDirection: "row",
    flex: 1,
    flexWrap: 'wrap',
    // marginRight: 810,
    marginLeft: 90,
    marginTop: 40
  },
  confirmPressable: {
    borderRadius: 20,
    height: 100,
    // marginBottom: 78,
    marginLeft: 574,
    marginRight: 574,

    backgroundColor: "#2196F3",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 2,
    minWidth: 88,
    paddingLeft: 16,
    paddingRight: 16
  },
  confirmPressableText: {
    color: "#fff",
    fontSize: 30,
    fontFamily: "Montserrat-SemiBold"
  }
})

const getButtonJsx = (text, onPress, key, containerStyle, textStyle, disabled = false) => {
  return (
    <TouchableOpacity
      key={key}
      onPress={onPress}
      disabled={disabled}
      style={containerStyle}
    >
      <Text style={textStyle}>
        {text}
      </Text>

    </TouchableOpacity>
  )
}

const DepartmentSelectionView = ({ departments, department, setDepartment, setVisible }) => {

  const dismiss = () => {
    setVisible(false);
  }

  const getDepartmentJsx = () => {
    return departments.length ? (
      departments.map(dep => getButtonJsx(
        dep,
        () => {
          setDepartment(dep);
          dismiss();
        },
        dep,
        [styles3.departmentPressable, department == dep ? { backgroundColor: "rgba(126,211,33,1)" } : null],
        styles3.departmentPressableText
      ))
    ) : (
      <Text>
        Loading...
      </Text>
    )
  }

  return (
    <View style={styles3.container}>
      <View style={[styles3.group2, { alignSelf: 'center' }]}>
        <View style={styles3.group3}>
          <View style={styles3.rect}>
            <Text style={styles3.selectAWard}>
              Select a Ward
            </Text>
            <View style={styles3.wardRow}>
              {getDepartmentJsx()}
            </View>
          </View>
        </View>
      </View>

    </View>
  )
}


const ItemList = ({ title, orders, setOrders, deleteItems, editItems = () => { } }) => {

  const [jsx, setJsx] = React.useState([]);

  React.useEffect(() => {
    // console.log("orders changed, looping to create scripts list", orders)
    setJsx(Object.entries(orders).reduce((list, [compartment, { department, items }]) => {
      // console.log(compartment, items);
      if (items) {
        list.push(items.map(({ id }, index) => (
          <View key={id} style={styles2.itemContainer}>
            <Text
              maxLength={20}
              style={styles2.itemStyle}
            >
              {id}
            </Text>
            <Text
              style={styles2.itemStyle}
            >
              {department}
            </Text>
            <TouchableOpacity onPress={() => editItems(compartment, index)} style={styles2.editPressable}>
              <Icon name={`numeric-${compartment}`} style={styles2.editPressableText}></Icon>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => deleteItems(compartment, index)} style={styles2.deletePressable}>
              <Icon name={"trash-can-outline"} style={styles2.deletePressableText}></Icon>
            </TouchableOpacity>
          </View>
        )));
      }
      return list;
    }, []));


  }, [orders])

  return (
    <>
      <View style={styles2.textWrapper1Stack}>
        <View style={styles2.rect1}>
          <View style={styles2.textWrapper2}>
            <Text numberOfLines={1} style={styles2.scriptsLoaded2}>
              {title}
            </Text>
          </View>
          <ScrollView>
            {jsx}
          </ScrollView>
        </View>
      </View>
    </>
  );
}


const ItemInsertionView = ({ initialOrder, departments, setVisible, onDismiss }) => {

  const [compartment, setCompartment] = React.useState(Object.keys(initialOrder)[0]);
  const [order, setOrder] = React.useState(initialOrder);
  const [department, setDepartment] = React.useState(initialOrder[compartment].department);
  const [showDepartmentSelection, setShowDepartmentSelection] = React.useState(false);
  const [inputItem, setInputItem] = React.useState('');
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  React.useEffect(() => {
    if (department) setOrder({ [compartment]: { ...order[compartment], department } });
  }, [department]) // update order with new department

  React.useEffect(() => {
    setSubmitDisabled(inputItem !== '' || (order[compartment].items?.length > 0 && !department));
  }, [order[compartment], department, inputItem])

  const split = (str, index) => [str.slice(0, index), str.slice(index)];

  const dismiss = () => {
    console.log("DISMISSORDER", order)
    onDismiss(order);
    setVisible(false);
  }

  const addItem = () => {
    if (inputItem.trim()) {
      // pharmacy's barcode string should be either 24 or 25 characters in length, depending on if the backslash character is included in the scan string eg \101424075920221001075160
      // the first ten chars are the case num, the last 10 are the script ID
      console.log("Item input: ", inputItem);
      let tempItemValue = inputItem.trim().replace(/\\/g, ''); // remove backslash if it is present
      let itemObj = { id: tempItemValue };
      if (tempItemValue.length > 20) {
        // based on string length, can assume that this must be a barcode scan input
        // tempItemValue = tempItemValue.substring(tempItemValue.length - 10); // script ID
        const [caseNum, scriptId] = split(tempItemValue, tempItemValue.length - 10);
        itemObj = { caseNum, id: scriptId, status: "dispatched" };
      }

      const items = order[compartment].items ?? [];
      if (!items.some(({ id }) => id === itemObj.id)) {
        setOrder({ [compartment]: { ...order[compartment], items: [itemObj, ...items] } }); // add item as first element in array while preserving other fields in order object
      }
      setInputItem('');
    }
  }

  const deleteItem = (compartment, index) => {
    console.log("DELETE ", order);

    let tempOrder = { ...order[compartment] };

    console.log("TEMPORDER", tempOrder)

    tempOrder.items.splice(index, 1);
    tempOrder['items'] = tempOrder.items
    setOrder({ [compartment]: tempOrder });
  }

  return showDepartmentSelection ? (
    <DepartmentSelectionView
      departments={departments}
      department={department}
      setDepartment={setDepartment}
      setVisible={setShowDepartmentSelection}
    />
  ) : (
    <KeyboardAvoidingView
      style={styles2.container}
      behavior="position"
    >
      <View style={styles2.group4}>
        <View style={styles2.materialButtonPink1ColumnColumnRow}>
          <View style={styles2.materialButtonPink1ColumnColumn}>
            <View style={styles2.materialButtonPink1Column}>
              {getButtonJsx(
                `Compartment ${compartment}`,
                () => { },
                `Compartment ${compartment}`,
                styles2.compartmentPressable,
                styles2.compartmentPressableText
              )}

              <Text style={styles2.selectWard}>
                Select Ward
              </Text>

              {department ? (
                <View style={{ justifyContent: 'space-around', flexDirection: 'row' }}>
                  {getButtonJsx(
                    department,
                    () => setShowDepartmentSelection(true),
                    "selectedDepartment",
                    styles2.departmentsPressable2,
                    styles2.departmentsPressableText
                  )}
                </View>
              ) : (
                <TouchableOpacity onPress={() => setShowDepartmentSelection(true)} style={styles2.departmentsPressable1}>
                  <Icon name={"plus-circle"} style={styles2.departmentsPressableText}></Icon>
                </TouchableOpacity>
              )}
            </View>
            {/* <View style={styles2.materialButtonPink1ColumnFiller}></View> */}
            <View style={styles2.group2}>
              <View style={styles2.rect2}>
                <View style={styles2.inputContainer}>
                  <Text style={styles2.addScriptId}>Add Script ID:</Text>
                  <Text style={styles2.helper}></Text>
                  <TextInput
                    placeholder="Input"
                    style={styles2.inputStyle}
                    value={inputItem}
                    blurOnSubmit={false}
                    onSubmitEditing={addItem}
                    onChangeText={setInputItem}
                  >
                  </TextInput>
                </View>

                {getButtonJsx(
                  "ADD",
                  addItem,
                  "add",
                  styles2.addPressable,
                  styles2.addPressableText
                )}
              </View>
            </View>
          </View>
          <View style={styles2.materialButtonPink1ColumnColumnFiller}></View>
          <View style={styles2.group}>
            <View style={styles2.textWrapper1StackStack}>
              <ItemList
                title="Scripts Loaded"
                orders={order}
                setOrders={setOrder}
                deleteItems={deleteItem}
              />
            </View>

            {getButtonJsx(
              "Confirm",
              () => dismiss(),
              "Confirm",
              [styles2.confirmPressable, submitDisabled ? { backgroundColor: "rgba(100,100,100,1)" } : null],
              [styles2.confirmPressableText, submitDisabled ? { color: "#000", } : null],
              submitDisabled
            )}
          </View>
        </View>
      </View>
    </KeyboardAvoidingView>
  )
}


function PadDemandScreen({ navigation, client }) {

  // STATES
  const [snackbarContent, setSnackbarContent] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [orders, setOrders] = React.useState({});   // For the accordion components
  const [loading, setLoading] = React.useState(false);    // For the loading animation behind the submit button
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [dispatcherApproved, setDispatcherApproved] = React.useState(false);
  const [showItemInsertion, setShowItemInsertion] = React.useState(false);
  const [departments, setDepartments] = useState([]);
  const [compartment, setCompartment] = useState('');
  const context = React.useContext(RobotsContext);

  React.useEffect(() => {                                 // Check for stale orders
    async function getStaleOrderList() {
      const sLst = await ioHelper.retrieveData("order_list");
      let staleOrders = sLst ? JSON.parse(sLst) : [];
      console.log("STALE ORDERS", staleOrders);
      if (Object.keys(staleOrders).length > 0) setOrders(staleOrders);
    }

    getStaleOrderList();
  }, []);

  React.useEffect(() => {
    console.log("ORDERS", orders)
    ioHelper.storeData("order_list", JSON.stringify(orders));
    setSubmitDisabled(Object.keys(orders).length > 0 ? false : true);
  }, [orders]);

  React.useEffect(() => {
    async function getDispatcherApprovedStatus() {
      setDispatcherApproved(context.dispatchStatus);
    }
    getDispatcherApprovedStatus();
  })

  // Get the list of departments from a user's clientId
  React.useEffect(() => {

    async function getDepartmentsList() {
      const postData = {
        command: "getDepartments",
        data: { clientId: await ioHelper.retrieveData("client_id") }
      };

      const { data } = await sendDataToFleetController('robots', postData);
      console.log("DEPARTMNENTS", data.departments);
      if (data?.departments) setDepartments(data.departments.filter(dep => !dep.toLowerCase().includes("test")));
    }

    getDepartmentsList();

  }, [])

  // METHODS
  const editOrder = compartment => {
    console.log("EDITORDER", compartment)
    setCompartment(compartment);
    setShowItemInsertion(true);
  }

  const deleteItem = (compartment, index) => {
    console.log("DELETE ", compartment, orders);

    let tempOrders = { ...orders };

    console.log("TEMPORDER", tempOrders[compartment])

    tempOrders[compartment].items.splice(index, 1);
    if (tempOrders[compartment].items.length === 0) {
      delete tempOrders[compartment]
    }

    setOrders(tempOrders);
  }

  const updateOrders = order => {
    console.log("UPDATEORDERRS", order);
    let tempOrders = { ...orders };
    let compartment = Object.keys(order)[0];

    const items = order[compartment].items ?? [];
    if (items.length === 0) {
      delete tempOrders[compartment];
    } else {
      tempOrders[compartment] = order[compartment];
    }
    setOrders(tempOrders);
    console.log("ORDERS", tempOrders);
  }

  const onDismissSnackBar = () => setShowSnackbar(false);

  const handleSnackbarAction = () => {
    if (snackbarContent.title === "Response") {
      navigation.navigate('loadScreenStack', snackbarContent.res.data)
    }
  }

  const handleSubmit = async () => {
    console.log("SUBMIT");
    if (Object.keys(orders).length < 1) {
      alert("Cannot submit an empty demand. Add a task before submitting.")
      return;
    }

    const compartments = Object.keys(orders).map(box => ({ id: +box, ...orders[box] }));
    const reqBody = {
      clientId: await ioHelper.retrieveData("client_id"),
      employeeId: await ioHelper.retrieveData("employee_id"),
      compartments
    };

    console.log("REQBODY", reqBody)
    setLoading(true);
    sendDataToQueueHandler(reqBody).then(async obj => {
      const res = obj.res;
      const status = obj.status;
      setLoading(false);
      if (status === 200) {

        setSnackbarContent({
          title: "Response",
          message: res.msg,
          res: res,
        })
        setShowSnackbar(true)

        setOrders({});
        ioHelper.removeData("order_list");

        navigation.navigate('DELIVERIES', res.data);

      } else if (status === 201) {
        setSnackbarContent({
          title: "Error",
          message: res.message
        })
        setShowSnackbar(true)
      };
    }).catch(err => {
      console.error(err);
      setLoading(false);
      alert("Request failed")
    })
  }

  const Item = ({ item }) => getButtonJsx(
    item,
    () => editOrder(item),
    item,
    [styles1.boxPressable, Object.keys(orders).includes(item) ? { backgroundColor: "rgba(126,211,33,1)" } : null],
    styles1.boxPressableText
  )

  return (
    <ScrollView style={{ flex: 1, backgroundColor: '#fff' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
      <ScrollView horizontal={true} style={{ backgroundColor: '#fff' }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>


        {dispatcherApproved ? showItemInsertion ? (
          <ItemInsertionView
            initialOrder={{ [compartment]: orders[compartment] ?? {} }}
            departments={departments}
            setVisible={setShowItemInsertion}
            onDismiss={updateOrders}
          />
        ) : (

          <View style={[styles1.container, { alignItems: 'center' }]}>
            <View style={[styles1.container, { justifyContent: 'space-evenly' }]}>
              <View style={{ width: 672, height: 640, justifyContent: 'center' }}>
                <View style={{ alignItems: 'center' }}>
                  <FlatList
                    data={["1", "2", "3", "4"]}
                    numColumns={2}
                    renderItem={Item}
                    keyExtractor={item => item.alt}
                  />
                </View>
              </View>
              {/* <View style={styles1.group3Filler}></View> */}
              <View style={styles1.group4}>
                <View style={styles1.textWrapper1StackStack}>
                  <ItemList
                    title="Scripts Loaded"
                    orders={orders}
                    setOrders={setOrders}
                    deleteItems={deleteItem}
                  />
                </View>
                {loading ? (<Loader />) : getButtonJsx(
                  "Confirm",
                  handleSubmit,
                  "Confirm",
                  [styles1.confirmPressable, submitDisabled ? { backgroundColor: "rgba(100,100,100,1)" } : null],
                  [styles1.confirmPressableText, submitDisabled ? { color: "#000" } : null],
                  submitDisabled
                )}
              </View>

            </View>
          </View>
        ) : (
          <Text>
            User not approved for dispatch. Please contact department manager.
          </Text>
        )}


        <Snackbar
          visible={showSnackbar}
          onDismiss={onDismissSnackBar}
          action={{
            label: 'OK',
            onPress: handleSnackbarAction,
          }}
          duration={3000}
        >
          <Text style={[styles1.bodyText, { color: "#FF0000" }]}>
            {snackbarContent.message}
          </Text>
        </Snackbar>

      </ScrollView>
    </ScrollView >
  )
}

export default PadDemandScreen
