let server = "https://api.ctrlrobotics.com";
// let ip = "13.244.120.115"; // aws
// let ip = "localhost"; 


let Constants = {
  
    ticket_backend: "https://w16x6mafy1.execute-api.us-east-1.amazonaws.com/prod",

    // socket_uri: `http://${ip}:20000`,
    // fleet_manager: `http://${ip}:20001`,
    // queue_handler: `http://${ip}:20002`,
    // notifications_server: `http://${ip}:20005`,
    // pudu_microservice: `http://${ip}:29050/api`,

    server,
    socket_uri: `${server}/socket`, // for rest api
    fleet_manager: `${server}/fleet`,
    queue_handler: `${server}/qh`,
    notifications_server: `${server}/ns`,
    pudu_microservice: `${server}/pudu/api`,

};

module.exports = Constants;
