import React from 'react'
import {
    StyleSheet, AppState, Platform,
    Button, Modal, View, Pressable, Text, TextInput,
    Image,
} from 'react-native';
import WebView from 'react-native-webview';

function LiveChat({ navigation, client }) {
    return (
        <>

            <View>

                <Pressable onPress={() => {
                    navigation.replace("DrawerNavigationRoutes");
                }}>
                    <Text> BACK </Text>
                </Pressable>

            </View>

            {Platform.OS === 'web' ? (
                // <iframe src="https://www.youtube.com/embed/cWDJoK8zw58">
                <iframe style={{
                    height: 1000,
                }}
                    src="https://tawk.to/chat/642b2d304247f20fefe99ea9/1gt4ah2jf">

                </iframe>
            ) : (
                <WebView

                    source={{
                        uri: "https://tawk.to/chat/642b2d304247f20fefe99ea9/1gt4ah2jf"
                    }}

                />


            )}

        </>
    )
}

export default LiveChat