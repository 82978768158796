import React, { useState } from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  Platform,
  Image,
  Animated,
} from "react-native";
import { ScrollView } from "react-native-web";
import { DataTable, RadioButton, Surface, Text } from "react-native-paper";

import ioHelper from "../Helpers/ioHelper";
import RobotsContext from "../Context/robotsContext";

import Loader from "../Components/Loader2";
import { TouchableOpacity } from "react-native-gesture-handler";

import { Audio } from "expo-av";

// import { useKeepAwake } from 'expo-keep-awake';

const { sendDataToFleetController } = require("../Helpers/apiHelper");

function AnnouncementScreen({ navigation, client }) {
  // COMPONENTS

  const CtrlLogo = () => {
    return (
      <View
        style={{
          position: "absolute",
          bottom: "20px",
          left: "20px",
          backgroundColor: "#000",
          zIndex: "100",
        }}
      >
        <TouchableOpacity
          style={{ padding: "16px" }}
          onPress={() => {
            navigation.navigate("SETTINGS");
          }}
        >
          <Image
            source={require("../../assets/ctrl_new_logo.png")}
            style={
              Platform.OS === "web"
                ? [
                    {
                      height: 60,
                      width: 110,
                      backgroundColor: "#000",
                      padding: "10px",
                    },
                  ]
                : [
                    {
                      height: 60,
                      width: 110,
                      backgroundColor: "#000",
                      padding: "10px",
                    },
                  ]
            }
          />
        </TouchableOpacity>
      </View>
    );
  };

  const Holding = () => {
    // Holding

    return (
      <SafeAreaView
        style={[
          styles.rootSafeArea,
          {
            flexDirection: "column",
          },
        ]}
      >
        {/** LEFT */}
        {/* <View
          style={[
            styles.view,
            {
              flex: 0.25,
              height: "90%",
              justifyContent: "space-between",
              flexDirection: "column",
            },
          ]}
        >

          <TouchableOpacity
            onPress={() => {
              navigation.navigate("SETTINGS");
            }}
          >
            <Image
              source={require("../../assets/ctrlrobotics.png")}
              style={
                Platform.OS === "web"
                  ? [
                      styles.imageWeb,
                      { height: 160, width: 160, backgroundColor: "black" },
                    ]
                  : [
                      styles.image,
                      { height: 110, width: 110, backgroundColor: "black" },
                    ]
              }
            />
          </TouchableOpacity>
        </View> */}

        {/** MIDDLE */}
        <View
          style={[
            styles.view,
            {
              flex: 0.75,
            },
          ]}
        >
          {/** Gif */}
          <View style={[styles.childView, {}]}>
            <Image
              style={
                Platform.OS === "web"
                  ? { width: 500, height: 500 }
                  : { width: 200, height: 200 }
              }
              source={require("../../assets/conveyer.gif")}
            />
          </View>

          {/** Text */}
          <View style={[styles.childView, {}]}>
            <Text style={[styles.bodyText]}>
              Watch here for notifications of Stat order deliveries...
            </Text>
          </View>

          {/** Text */}
          <View style={[styles.childView, {}]}>
            <Text style={[styles.bodyText, { fontSize: 22 }]}>
              Remember to collect the packages and deliver them to the patients
              bed side as soon as possible.
            </Text>
          </View>
        </View>

        {/** RIGHT */}
        {/* <View style={[styles.view, {
          flex: 0.25,
          height: '90%', justifyContent: 'flex-end',
        }]}>
          <View style={[{ backgroundColor: "#fff", padding: 10 }]}>
            <Image
              source={require('../../assets/netcare.jpeg')}
              style={Platform.OS === 'web' ?
                [styles.imageWeb, { height: 130, width: 130 }]
                : [styles.image, { height: 80, width: 80 }]}
            />

          </View>
        </View> */}
      </SafeAreaView>
    );
  };

  const Arrived = () => {
    // Arrived

    const itemStatuses = [
      { name: "Correct", value: "accepted" },
      { name: "Missing", value: "missing" },
      { name: "Incorrect - Return to Pharmacy", value: "returned" },
    ];

    const [scripts, setScripts] = React.useState([]);
    const [tableHeaders, setTableHeaders] = React.useState([]);
    const [selected, setSelected] = React.useState([]); // an array of the i, j indices of a script

    function isSelected(arr) {
      if (selected === arr) return true;
      if (selected.length !== arr.length) return false;
      return selected.every((val, idx) => val === arr[idx]);
    }

    function handleSelected(arr) {
      if (isSelected(arr)) setSelected([]);
      else setSelected(arr);
    }

    function updateScript(newStatus) {
      const [i1, i2] = selected;
      const scriptsCopy = [...scripts];
      scriptsCopy[i1][i2].status = newStatus;
      setScripts(scriptsCopy);
    }

    // React.useEffect(() => {
    //   setScripts(
    //     announcement.scripts[announcement.assignee.destinationIndex - 1]
    //   );
    // }, [announcement.scripts[announcement.assignee.destinationIndex - 1]]);

    React.useEffect(() => {
      const hasCaseNum = scripts
        .flat()
        .some((obj) => obj.caseNum !== undefined);
      if (hasCaseNum) {
        setTableHeaders([
          { name: "Script ID", key: "id" },
          { name: "Case Number", key: "caseNum" },
          { name: "Status", key: "status" },
        ]);
      } else {
        setTableHeaders([
          { name: "Item", key: "id" },
          { name: "Status", key: "status" },
        ]);
      }
    }, [scripts]);

    // Set up background animation loop
    const animation = React.useRef(new Animated.Value(0)).current;
    // const colorInterpolation = animation.interpolate({
    //   inputRange: [0, 1],
    //   outputRange: ["rgb(0,0,0)", "rgb(225,0,0)"],
    // });

    const colorInterpolation = "#fff";

    React.useEffect(() => {
      const animateBg = () => {
        Animated.loop(
          Animated.sequence([
            Animated.timing(animation, {
              toValue: 1,
              duration: 700,
              useNativeDriver: false,
            }),
            Animated.delay(700),
            Animated.timing(animation, {
              toValue: 0,
              duration: 2100,
              useNativeDriver: false,
            }),
          ])
        ).start();
      };

      animateBg();
    }, []);

    return (
      <Animated.View
        style={[
          styles.rootSafeArea,
          {
            // Bind bg color to animated value
            backgroundColor: colorInterpolation,
          },
        ]}
      >
        {/** LEFT */}
        <Animated.View
          style={[
            styles.view,
            styles.arrivedViewModifier,
            {
              backgroundColor: colorInterpolation,
              flex: 0.05,
              height: "90%",
              paddingLeft: 0,
            },
          ]}
        ></Animated.View>

        {/** MIDDLE */}
        <Animated.View
          style={[
            styles.view,
            styles.arrivedViewModifier,
            {
              backgroundColor: colorInterpolation,
              flex: 0.75,
              height: "90%",
            },
          ]}
        >
          {/* <TouchableOpacity
            onPress={() => {
              navigation.navigate("SETTINGS");
            }}
          > */}
          {/* <TouchableOpacity
            onPress={() => {
              navigation.navigate("SETTINGS");
            }}
          >
            <Image
              source={require("../../assets/ctrlrobotics.png")}
              style={
                Platform.OS === "web"
                  ? [
                      styles.imageWeb,
                      { height: 160, width: 160, backgroundColor: "#000" },
                    ]
                  : [
                      styles.image,
                      { height: 110, width: 110, backgroundColor: "#000" },
                    ]
              }
            />
          </TouchableOpacity> */}
          <Image
            source={require("../../assets/package.gif")}
            style={
              Platform.OS === "web"
                ? [
                    styles.imageWeb,
                    { height: 400, width: 400, backgroundColor: "#fff" },
                  ]
                : [
                    styles.image,
                    { height: 180, width: 180, backgroundColor: "#fff" },
                  ]
            }
          />
          {/* </TouchableOpacity> */}

          <Text
            style={[
              styles.bodyText,
              { fontWeight: "bold", fontSize: 32, color: "#000" },
            ]}
          >
            Your delivery has arrived. Please collect your package from{" "}
            {announcement?.assignee?.alias}.
          </Text>

          <Text
            style={[
              styles.bodyText,
              styles.arrivedTextModifier,
              { marginTop: 50 },
            ]}
          >
            Contents
          </Text>

          {scripts?.length > 0 ? (
            <DataTable style={[styles.surface, styles.arrivedSurfaceModifier]}>
              <DataTable.Header>
                {tableHeaders.map((header) => (
                  <DataTable.Title key={header.key}>
                    <Text
                      style={[
                        styles.bodyText,
                        styles.arrivedTextModifier,
                        { textAlign: "left" },
                      ]}
                    >
                      {header.name}
                    </Text>
                  </DataTable.Title>
                ))}
              </DataTable.Header>

              {scripts.map((shelf, i) => {
                console.log("SHELF: ", shelf);
                return shelf.map((item, j) => (
                  <DataTable.Row key={j} onPress={() => handleSelected([i, j])}>
                    {tableHeaders
                      .filter((h) => h.key !== "status")
                      .map(
                        (
                          header,
                          k // handle the item's status separately (outside of loop)
                        ) => (
                          <DataTable.Cell key={j + "+" + k}>
                            {item[header.key] ?? null}
                          </DataTable.Cell> // header.key may not be defined in the item
                        )
                      )}
                    <DataTable.Cell>
                      {isSelected([i, j]) ? (
                        <RadioButton.Group
                          onValueChange={(newStatus) => updateScript(newStatus)}
                          value={item.status}
                        >
                          {itemStatuses.map((options) => (
                            <View key={j + "+" + options.value}>
                              <Text>{options.name}</Text>
                              <RadioButton value={options.value} />
                            </View>
                          ))}
                        </RadioButton.Group>
                      ) : item.status && item.status !== "dispatched" ? (
                        itemStatuses.find((opt) => opt.value === item.status)
                          .name
                      ) : null}
                    </DataTable.Cell>
                  </DataTable.Row>
                ));
              })}
            </DataTable>
          ) : null}
        </Animated.View>

        {/** RIGHT */}
        <Animated.View
          style={[
            styles.view,
            styles.arrivedViewModifier,
            {
              backgroundColor: colorInterpolation,
              flex: 0.05,
              paddingRight: 0,
              height: "90%",
              justifyContent: "flex-start",
            },
          ]}
        >
          {/* <Button style={[styles.outlinedButton]}
            labelStyle={styles.buttonText}
            color={'#FFF'}
            mode={'outlined'}
            onPress={() => console.log("HELP click @CollectorScreen2")}>
            HELP
          </Button> */}
        </Animated.View>
      </Animated.View>
    );
  };

  const InProgress = () => {
    // In progress
    return (
      <SafeAreaView
        style={[
          styles.rootSafeArea,
          {
            backgroundColor: "#fff",
          },
        ]}
      >
        {/** LEFT */}
        <View
          style={[
            styles.view,
            styles.inPViewModifier,
            {
              flex: 0.05,
              height: "90%",
              paddingLeft: 0,
            },
          ]}
        ></View>

        {/** MIDDLE */}
        <View
          style={[
            styles.view,
            styles.inPViewModifier,
            {
              flex: 0.75,
              height: "90%",
            },
          ]}
        >
          {/* <TouchableOpacity
            onPress={() => {
              navigation.navigate("SETTINGS");
            }}
          >
            <Image
              source={require("../../assets/ctrlrobotics.png")}
              style={
                Platform.OS === "web"
                  ? [
                      styles.imageWeb,
                      { height: 160, width: 160, backgroundColor: "#000" },
                    ]
                  : [
                      styles.image,
                      { height: 110, width: 110, backgroundColor: "#000" },
                    ]
              }
            />
          </TouchableOpacity> */}

          <Image
            source={require("../../assets/on_the_way.gif")}
            style={
              Platform.OS === "web"
                ? [styles.imageWeb, { height: 500, width: 500 }]
                : [styles.image, { height: 200, width: 200 }]
            }
          />

          <Text style={[styles.bodyText, styles.inPTextModifier]}>
            Expect Delivery Soon
          </Text>

          <Surface style={[styles.surface, styles.inPSurfaceModifier]}>
            <Text style={[styles.bodyText, styles.inPTextModifier]}>
              {announcement?.assignee
                ? new Date(announcement.assignee.pickupTime).toLocaleString()
                : null}
            </Text>
          </Surface>

          <Text
            style={[
              styles.bodyText,
              styles.inPTextModifier,
              {
                marginTop: 50,
              },
            ]}
          >
            Robot coming from
          </Text>

          <Surface style={[styles.surface, styles.inPSurfaceModifier]}>
            <Text style={[styles.bodyText, styles.inPTextModifier]}>
              {announcement.from}
            </Text>
          </Surface>

          <Text
            style={[
              styles.bodyText,
              styles.inPTextModifier,
              {
                marginTop: 50,
              },
            ]}
          >
            Trip ID
          </Text>

          <Surface style={[styles.surface, styles.inPSurfaceModifier]}>
            <Text style={[styles.bodyText, styles.inPTextModifier]}>
              {announcement.shortId}
            </Text>
          </Surface>
        </View>

        {/** RIGHT */}
        <View
          style={[
            styles.view,
            styles.inPViewModifier,
            {
              flex: 0.05,
              paddingRight: 0,
              height: "90%",
              justifyContent: "flex-start",
            },
          ]}
        >
          {/* <Button style={[styles.outlinedButton]}
            labelStyle={styles.buttonText}
            color={'#FFF'}
            mode={'outlined'}
            onPress={() => console.log("HELP")}>
            HELP
          </Button> */}
        </View>
      </SafeAreaView>
    );
  };

  const [collectorState, setCollectorState] = React.useState({
    screen1: true,
    screen2: false,
    screen3: false,
    screen4: false,
    screen5: false,
    screen6: false,
  });
  const [alias, setAlias] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [announcement, setAnnouncement] = React.useState({});
  const context = React.useContext(RobotsContext);

  // Load announcements data, and choose which collector component to render
  const isMounted = React.useRef(null); // Use effect cleanup flag

  // Check for any tasks currently in-progress or arrived
  React.useEffect(() => {
    console.log("1---EFFECT---1");

    const client = context.client;

    // executed on component mount
    isMounted.current = true;

    if (isMounted.current) showRelevantAnnouncement();

    async function showRelevantAnnouncement() {
      const dept = await ioHelper.retrieveData("department");
      const docs = await client.service("announcements").find({
        query: {
          taskStatus: {
            $in: ["in-progress", "arrived", "offloading", "returning"],
          },
          $sort: {
            createdAt: -1,
          },
          $or: [
            {
              to: dept,
            },
            {
              from: dept,
            },
          ],
        },
      });

      // const latest = docs.data[0];

      if (docs.data)
        docs.data.forEach((doc) => {
          console.log("Doc: ", doc._id);

          if (doc) {
            console.log("INIT:", doc.taskStatus);
            switch (doc.taskStatus) {
              case "in-progress":
                setAnnouncement(doc);
                if (doc.to === dept) {
                  setCollectorState({
                    screen1: false,
                    screen2: false,
                    screen3: true,
                  });
                }
                if (doc.from === dept) {
                  setCollectorState({
                    screen1: true,
                    screen2: false,
                    screen3: false,
                  });
                }

                break;
              case "arrived":
              case "offloading":
                setAnnouncement(doc);
                if (doc.to === dept) {
                  setCollectorState({
                    screen1: false,
                    screen2: true,
                    screen3: false,
                  });
                }
                if (doc.from === dept) {
                  setCollectorState({
                    screen1: true,
                    screen2: false,
                    screen3: false,
                  });
                }
                break;
              default:
                setAnnouncement({});
                setCollectorState({
                  screen1: true,
                  screen2: false,
                  screen3: false,
                });
                break;
            }
          }
        });
    }

    return () => {
      // executed on component unmount
      isMounted.current = false;
    };
  }, [context.announcement]);

  // Watch for signal to play sounds
  const [playbackObject, setPlaybackObject] = React.useState();
  React.useEffect(() => {
    async function loadPlaybackObject() {
      console.log("Loading playback object");
      const { sound } = await Audio.Sound.createAsync(
        require("../../assets/ding.wav")
      );
      setPlaybackObject(sound);
    }

    loadPlaybackObject();
  }, []);
  React.useEffect(() => {
    collectorState.screen2 === true ? playSound() : stopSound();

    return () => {
      stopSound();
    };
  }, [collectorState, playbackObject]);

  // METHODS

  async function playSound() {
    if (playbackObject) {
      playbackObject.setIsLoopingAsync(true);
      const alreadyPlaying = (await playbackObject.getStatusAsync()).isPlaying;
      console.log("Already playing?", alreadyPlaying);
      if (!alreadyPlaying) {
        console.log("Playing Sound");
        await playbackObject.playAsync();
      }
    }
  }

  async function stopSound() {
    if (playbackObject) await playbackObject.stopAsync();
  }

  return (
    <>
      {collectorState.screen1 ? (
        <Holding />
      ) : collectorState.screen2 ? (
        <Arrived />
      ) : collectorState.screen3 ? (
        <InProgress />
      ) : null}
      <CtrlLogo />
    </>
  );
}

const styles = StyleSheet.create({
  rootSafeArea: {
    borderWidth: 0,
    borderColor: "red",
    borderStyle: "solid",
    borderRadius: 0,
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  view: {
    borderWidth: 0,
    borderColor: "blue",
    borderStyle: "solid",
    borderRadius: 0,
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    elevation: 0,
    marginHorizontal: "1%",
    marginVertical: "1%",
    paddingHorizontal: 10,
  },
  childView: {
    borderWidth: 0,
    borderColor: "green",
    borderStyle: "solid",
    borderRadius: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    elevation: 12,
    marginVertical: 10,
  },
  surface: {
    backgroundColor: "#fff",
    borderRadius: 15,
    elevation: 15,
    paddingHorizontal: 15,
  },

  bodyText: {
    textAlign: "center",
    color: "#000",
    fontSize: 28,
    marginVertical: 8,
    fontFamily: "Montserrat-Regular",
    fontWeight: "bold",
  },
  buttonText: {
    fontFamily: "Montserrat-Bold",
    fontWeight: "bold",
    color: "#000",
    fontSize: 22,
  },

  outlinedButton: {
    borderColor: "#000",
    borderWidth: 1.5,
  },

  imageWeb: { width: 150, height: 150, resizeMode: "contain", margin: 0 },
  image: { width: 100, height: 100, resizeMode: "contain", margin: 0 },

  // CollectorScreen1 (holding)
  holTextModifier: {
    color: "#000",
    fontWeight: "bold",
  },
  holSurfaceModifier: {
    marginVertical: 10,
  },
  holViewModifier: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: 10,
  },

  // CollectorScreen2 (arrived)
  arrivedTextModifier: {
    color: "#000",
  },
  arrivedSurfaceModifier: {
    marginVertical: 10,
  },
  arrivedViewModifier: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: 10,
  },

  // CollectorScreen3 (in-progress)
  inPTextModifier: {
    color: "#000",
  },
  inPSurfaceModifier: {
    marginVertical: 5,
  },
  inPViewModifier: {
    backgroundColor: "#fff",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: 10,
  },

  // CollectorScreen4 (pin)
  collectorView: {
    marginVertical: 5,
    borderColor: "red",
    borderWidth: 0,
    justifyContent: "center",
    padding: 2,
  },
  collectorViewChild: {
    borderWidth: 0,
    borderColor: "blue",
    borderStyle: "solid",
    minWidth: 300,
  },
  collectorViewModifier: {
    borderRadius: 10,
    backgroundColor: "#333",
    opacity: 0.8,
    elevation: 15,
  },
  collectorText: {
    borderRadius: 5,
    textAlign: "center",
    color: "#333",
    fontSize: 18,
    margin: 10,
    backgroundColor: "#ddd",
    elevation: 12,
  },
});

export default AnnouncementScreen;
