import React, { useState, createRef } from 'react';
import {
    View, Text, SafeAreaView, Platform, TouchableOpacity, ScrollView, StyleSheet,
} from 'react-native';
import {
    Dialog, Button, Surface,
    DataTable,
} from 'react-native-paper';

import Loader from '../Components/Loader2'




import RobotsContext from '../Context/robotsContext';

import ioHelper from '../Helpers/ioHelper';





function LogsScreen({ navigation, client }) {

    // COMPONENTS
    const SubRow = ({ value, row }) => {

        if (value === 'destinationQueue') return (
            <DataTable.Cell
                style={[styles.limitedWidth, {
                    paddingVertical: 5
                }]}
            >


                <View style={{
                    flex: 1, flexDirection: 'column',
                    borderWidth: 0, borderColor: 'black', borderStyle: 'solid',
                }}>

                    <View style={{
                        flex: 1, flexDirection: 'column',
                        borderWidth: 0, borderColor: 'black', borderStyle: 'solid',
                    }}>
                        <Text>{row[value]}</Text>
                    </View>
                    {
                        <View style={{
                            flex: 1, flexDirection: 'column',
                            borderWidth: 0, borderColor: 'black', borderStyle: 'solid',
                        }}>
                            {
                                row.assignee?.destinationQueue ? (
                                    row.assignee.destinationQueue.map((x, i) => (
                                        <View key={`${x}-${i}`} style={{
                                            flex: 1, flexDirection: 'column',
                                            borderWidth: 0, borderColor: 'black', borderStyle: 'solid',
                                        }}>
                                            <Text>{x}</Text>
                                        </View>
                                    )
                                    )
                                ) : (
                                    null
                                )
                            }
                        </View>
                    }
                </View>


            </DataTable.Cell>
        )

        else if (row.subRows) return (
            <DataTable.Cell style={[styles.limitedWidth, {
                paddingRight: 20,
            }]}
            >

                <View style={{
                    flex: 1, flexDirection: 'column',
                    borderWidth: 0, borderColor: 'red', borderStyle: 'solid',
                }}>

                    <View style={{
                        flex: 1, flexDirection: 'column',
                        borderWidth: 0, borderColor: 'blue', borderStyle: 'solid',
                    }}>
                        {
                            row.subRows.map((x, i) => (
                                <View key={`${x}-${i}`} style={{
                                    flex: 1, flexDirection: 'column',
                                    borderWidth: 0, borderColor: 'green', borderStyle: 'solid',
                                }}>
                                    <Text>{x[value]}</Text>
                                </View>
                            )
                            )
                        }
                    </View>




                </View>


            </DataTable.Cell>

        )

        else return (
            <DataTable.Cell
                style={[styles.limitedWidth]}
            >

                {row[value]}

            </DataTable.Cell>
        )

    }

    // STATES + HOOKS 

    const isMounted2 = React.useRef(null);

    const context = React.useContext(RobotsContext);



    const headings = [
        {
            value: "ID",
            accessor: "shortId",
        },
        {
            value: "Scripts",
            accessor: "assignee.compartments",
        },
        {
            value: "Dispatcher",
            accessor: "assignee.dispatchedBy",
        },
        {
            value: "Receiver",
            accessor: "assignee.receivedBy",
        },
        {
            value: "Ward",
            accessor: "ward",
        },
        {
            value: "Robot",
            accessor: "assignee.alias",
        },
        {
            value: "Task Status",
            accessor: "taskStatus",
        },
        {
            value: "Elapsed Time",
            accessor: "tripTime",
        },
        {
            value: "TimeCreated",
            accessor: "createdAt",
        },
        {
            value: "Destination Queue",
            accessor: "assignee.destinationQueue",
        },
    ];

    const numberOfItemsPerPageList = [10, 20, 50, 100];
    const [totalDocs, setTotalDocs] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, totalDocs);

    const [loading, setLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([]);
    const [robots, setRobots] = React.useState([]);

    React.useEffect(() => {
        // executed when component mounted
        isMounted2.current = true;

        async function getDeliveryHistory() {
            setLoading(true)
            const cId = await ioHelper.retrieveData("client_id")
            client.service("announcements").timeout = 15000;
            client.service("announcements").find({
                query: {
                    $skip: from,
                    $limit: to - from,
                    $sort: {
                        createdAt: -1
                    },
                    clientId: cId,
                }
            }).then(docs => {
                setTotalDocs(docs.total)
                parseData(docs.data);    // Mutation 
                setTableData(docs.data);
                setLoading(false)
            }).catch(err => {
                console.error("Error @getDeliveryHistory", err);
                navigation.replace("Auth");
            })

            client.service("robots").find({
                query: { clientId: await ioHelper.retrieveData("client_id") }
            }).then(docs => {
                setRobots(docs.data);
            });

        }




        function parseData(data) {

            for (let i = 0, n = data.length; i < n; i++) {           // Loop all announcements
                const dQ = data[i].assignee?.destinationQueue;
                const receivers = data[i].assignee?.receivedBy;
                // if (receivers) data[i].receivedBy = receivers[`${dQ[1]}`];


                data[i].createdAt = (new Date(data[i].createdAt)).toLocaleString();

                const tripTimes = data[i].timestamps;
                let tTime = null;
                if (tripTimes) {
                    let q = tripTimes.pending;
                    let c = tripTimes.completed
                    if (q && c) {
                        if (typeof q !== "string") q = q[0];
                        if (typeof c !== "string") c = c[0];
                        tTime = Date.parse(c) - Date.parse(q);
                        tTime = tTime / (1000 * 60)                  // Convert milli seconds to minutes
                        tTime = tTime.toFixed(2)
                    }
                }
                data[i].tripTime = tTime;

                const scripts = data[i].scripts;
                if (scripts) { // old announcement format
                    for (let j = 0, m = scripts.length; j < m; j++) {     // Loop scripts 
                        let scriptListForDest = scripts[j].flat();
                        if (scriptListForDest[0].id) scriptListForDest = scriptListForDest.map(s => s.id); // check if script items are strings or objects
                        let rBy = null;
                        if (receivers) rBy = receivers[`${dQ[j + 1]}`];

                        const obj = {
                            scripts: scriptListForDest.join(" + "),
                            ward: dQ[j + 1],
                            receivedBy: rBy,
                        };

                        if (data[i].subRows) {
                            data[i]["subRows"].push(obj)
                        } else {
                            data[i]["subRows"] = [obj]
                        }
                    }
                    // data[i].scripts = data[i].scripts[0].join(" + ");
                } else { // then the document has the newer strucutre with assignee.compartments
                    const compartments = data[i].assignee?.compartments;
                    for (let j = 0, m = compartments.length; j < m; j++) {     // Loop scripts 
                        let scriptListForDest = compartments[j].items;
                        if (scriptListForDest[0].id) scriptListForDest = scriptListForDest.map(s => s.id); // check if script items are strings or objects
                        let rBy = null;
                        const destinationIndex = compartments[j].destinationIndex;
                        const destination = data[i].assignee.destinationQueue[destinationIndex]
                        if (receivers) rBy = receivers.find(rObj => rObj.department === destination)?.user;
                        const obj = {
                            scripts: scriptListForDest.join(" + "),
                            ward: dQ[destinationIndex],
                            receivedBy: rBy,
                        };

                        if (data[i].subRows) {
                            data[i]["subRows"].push(obj)
                        } else {
                            data[i]["subRows"] = [obj]
                        }
                    }
                    // data[i]["scripts"] = compartments.map(({items}) => items.join(" + ")).join(" + ");
                }
            }

        }

        if (isMounted2.current) getDeliveryHistory();


        return () => {
            // executed when unmount
            isMounted2.current = false;
        }

    }, [context, totalDocs, page, numberOfItemsPerPage])





    return (
        <SafeAreaView style={styles.rootSafeArea}>

            <ScrollView style={styles.rootScrollView}
                nestedScrollEnabled={true}
            >
                <ScrollView style={styles.rootScrollView}
                    horizontal={true}
                >





                    <DataTable style={{
                        minWidth: 1800,
                    }}>


                        <DataTable.Pagination
                            page={page}
                            numberOfPages={Math.ceil(totalDocs / numberOfItemsPerPage)}
                            onPageChange={page => setPage(page)}
                            label={`${from + 1}-${to} of ${totalDocs}`}
                            showFastPaginationControls
                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                            numberOfItemsPerPage={numberOfItemsPerPage}
                            onItemsPerPageChange={onItemsPerPageChange}
                            selectPageDropdownLabel={'Rows per page'}
                        />


                        <DataTable.Header>

                            {headings.map((obj, index) => {
                                return (
                                    <DataTable.Title style={[styles.limitedWidth]} key={`h-${index}`} >
                                        {obj.value}
                                    </DataTable.Title>
                                )
                            })}

                        </DataTable.Header>


                        {
                            (
                                tableData.length > 0 ? (
                                    tableData.map((row, index) => {
                                        return (
                                            <DataTable.Row style={{
                                                minHeight: 80,
                                            }} key={`r-${index}`}>
                                                <DataTable.Cell style={[styles.limitedWidth]}>{row.shortId}</DataTable.Cell>
                                                <SubRow row={row} value={'scripts'} />
                                                <DataTable.Cell style={[styles.limitedWidth]}>{row.assignee?.dispatchedBy}</DataTable.Cell>
                                                <SubRow row={row} value={'receivedBy'} />
                                                <SubRow row={row} value={'ward'} />
                                                <DataTable.Cell style={[styles.limitedWidth]}>{row.assignee?.alias ?? robots.find(robot => robot.robotId === row.robotId)?.robotMeta?.alias}</DataTable.Cell>
                                                <DataTable.Cell
                                                    style={
                                                        row.taskStatus === "completed"
                                                            ?
                                                            (
                                                                [styles.limitedWidth, {
                                                                    backgroundColor: 'green',
                                                                }]
                                                            )
                                                            :
                                                            (
                                                                row.taskStatus === "dropped"
                                                                    ?
                                                                    (
                                                                        [styles.limitedWidth, {
                                                                            backgroundColor: 'red',
                                                                        }]
                                                                    )
                                                                    :
                                                                    row.taskStatus === "cancelled"
                                                                        ?
                                                                        (
                                                                            [styles.limitedWidth, {
                                                                                backgroundColor: 'orange',
                                                                            }]
                                                                        )
                                                                        :
                                                                        (
                                                                            [styles.limitedWidth, {
                                                                                backgroundColor: '#fff',
                                                                            }]
                                                                        )
                                                            )
                                                    }
                                                >
                                                    <Text
                                                        style={
                                                            (
                                                                row.taskStatus === "completed"
                                                                | row.taskStatus === "dropped"
                                                                | row.taskStatus === "cancelled"
                                                            ) ? (
                                                                {
                                                                    color: 'white',
                                                                }
                                                            ) : null
                                                        }
                                                    > {row.taskStatus} </Text>
                                                </DataTable.Cell>

                                                <DataTable.Cell style={[styles.limitedWidth, {
                                                    paddingLeft: 20
                                                }]}>{row.tripTime}</DataTable.Cell>
                                                <DataTable.Cell style={[styles.limitedWidth]}>{row.createdAt}</DataTable.Cell>
                                                {/* <DataTable.Cell>{row.assignee?.destinationQueue}</DataTable.Cell> */}
                                                <SubRow row={row} value={'destinationQueue'} />

                                            </DataTable.Row>
                                        )
                                    })
                                ) : (
                                    <Text>
                                        No result
                                    </Text>
                                )
                            )
                        }






                    </DataTable>







                </ScrollView>
            </ScrollView>

        </SafeAreaView >
    )
}

export default LogsScreen


const styles = StyleSheet.create({
    thg_text: {
        padding: 5,
        fontSize: 14,
        color: "#000",
    },
    bodyText: {
        color: '#000',
        fontSize: 14,
    },
    headingText: {
        fontSize: 14,
        marginBottom: 5,
        marginLeft: 10,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "#000"
    },
    rootSafeArea: {
        flex: 1,
        backgroundColor: '#fff',
    },
    rootScrollView: {
        flex: 1,
        padding: 0,
        borderColor: "#00FF00",
        borderWidth: 0,
        borderStyle: 'solid',
    },
    surface: {
        elevation: 2,
        flex: 1,
        justifyContent: 'flex-start',
        borderColor: "#000",
        borderWidth: 0,
        borderStyle: 'solid',
        borderRadius: 5,
        padding: 5,
        marginBottom: 10, marginTop: 5, marginHorizontal: 10,
        backgroundColor: "#eee",
        elevation: 12,
    },



    limitedWidth: {
        maxWidth: 200,
        minWidth: 200,
        overflow: 'hidden',

    }
});