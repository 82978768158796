// Example of Splash, Login and Sign Up in React Native
// https://aboutreact.com/react-native-login-and-signup/

// Import React and Component
import React, { useState, createRef } from "react";
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  Image,
  KeyboardAvoidingView,
  Keyboard,
  TouchableOpacity,
  ScrollView,
  Picker,
  Platform,
} from "react-native";

import DropDownPicker from "react-native-dropdown-picker";

import Loader from "./Components/Loader";
import * as Application from "expo-application";
import { sendDataToFleetController } from "./Helpers/apiHelper";

import ioHelper from "./Helpers/ioHelper";

function RegisterScreen(props) {
  // COMPONENTS
  const FormFields = () => {
    const fieldNames = {
      clientName: "Company",
      department: "Department",
      username: "Username",
      // email: "E-mail",
      password: "Password",
      pin: "PIN",
      employeeId: "Clock Number",
    };

    const [formData, setFormData] = useState(
      // create new object with same keys as fieldNames but with values = ''
      Object.keys(fieldNames).reduce(
        (obj, newKey) => ({ ...obj, [newKey]: "" }),
        {}
      )
    );

    const [clientNames, setClientNames] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [openClientNamesDropdown, setOpenClientNamesDropdown] =
      useState(false);
    const [departments, setDepartments] = useState([]);
    const [openDepartmentsDropdown, setOpenDepartmentsDropdown] =
      useState(false);
    const [errortext, setErrortext] = useState("");
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
      async function getCliendIdAndNameList() {
        const postData = { command: "getClientIdsAndNames" };

        const { data } = await sendDataToFleetController("robots", postData);
        console.log("getClientIdsAndNames: ", data);
        if (data) {
          let tmpClientNames = [];
          let tmpClientIds = [];
          for (let i = 0; i < data.length; i++) {
            tmpClientNames.push(data[i].clientName);
            tmpClientIds.push(data[i].clientId);
          }
          setClientNames(tmpClientNames);
          setClientIds(tmpClientIds);
        }
      }

      getCliendIdAndNameList();
      console.log(clientNames, clientIds);
    }, []);

    React.useEffect(() => {
      console.log("formData.clientName:", formData.clientName);

      async function getDepartmentsList() {
        setDepartments([]); // remove all departments while loading new departments
        const clientId = clientIds[clientNames.indexOf(formData.clientName)];
        const postData = {
          command: "getDepartments",
          data: { clientId },
        };

        const { data } = await sendDataToFleetController("robots", postData);
        if (data?.departments) {
          setDepartments(data.departments);
        }
      }

      getDepartmentsList();
    }, [formData.clientName]);

    // For the web dropdowns
    React.useEffect(() => {
      if (deviceType === "web") {
        console.log("clientNames", clientNames);
        setFormData({ ...formData, clientName: clientNames[0] });
      }
    }, [clientNames]);

    React.useEffect(() => {
      if (deviceType === "web") {
        console.log("departments", departments);
        setFormData({ ...formData, department: departments[0] });
      }
    }, [departments]);

    const handleSubmitButton = async () => {
      setErrortext("");

      // Validate form completion
      const fields = Object.keys(formData);
      for (let i = 0, n = fields.length; i < n; i++) {
        console.log(fieldNames[fields[i]]);
        if (!formData[fields[i]]) {
          alert("Please fill " + fieldNames[fields[i]]);
          return;
        }
      }

      //Show Loader
      setLoading(true);

      let clientData = { ...formData }; // shallow copy is all that's needed
      delete Object.assign(clientData, { alias: clientData["username"] })[
        "username"
      ]; // replace 'username' key with 'alias'

      let clientId = clientIds[clientNames.indexOf(formData.clientName)];
      delete Object.assign(clientData, { clientId: clientId })["clientName"]; // replace 'clientName' key/value with 'clientId'
      clientData["deviceId"] = deviceId;
      clientData["accessLevel"] =
        clientData.department.toLowerCase() === "pharmacy"
          ? "dispatcher"
          : "collector";

      var dataToSend = {
        command: "addNewClient",
        data: clientData,
      };
      console.log("Client data: ", dataToSend);
      try {
        let responseJson = await sendDataToFleetController(
          "clients",
          dataToSend
        );
        // Hide Loader
        setLoading(false);
        console.log("Server response:", responseJson);
        // If server response message same as Data Matched
        if (responseJson.data?._id) {
          setIsRegistrationSuccess(true);
          await ioHelper.storeData("department", responseJson.data.department);
          console.log("Registration Successful.");
        } else {
          setErrortext(responseJson.message);
        }
      } catch (error) {
        //Hide Loader
        setLoading(false);
        console.error(error);
      }
    };

    // COMPONENTS
    const PickerWeb = ({ type, selected, setSelected, contents }) => {
      return (
        <>
          <Text style={styles.TextStyle}>Select a {type}</Text>
          <Picker
            selectedValue={selected}
            style={[styles.inputStyle, { backgroundColor: "black" }]}
            itemStyle={styles.TextStyle}
            onValueChange={(itemValue) => setSelected(itemValue)}
          >
            {contents.map((x) => (
              <Picker.Item key={`p-${x}`} label={x} value={x} />
            ))}
          </Picker>
        </>
      );
    };

    const PickerAndroid = ({
      open,
      value,
      placeholder,
      items,
      setOpen,
      setValue,
      setItems,
    }) => {
      return (
        <DropDownPicker
          itemSeparator={true}
          listMode="MODAL"
          bottomOffset={300}
          open={open}
          value={value}
          placeholder={placeholder}
          items={items}
          setOpen={setOpen}
          setValue={setValue}
          setItems={setItems}
          // style={styles.dropdownPicker}
          textStyle={styles.TextStyle}
          labelStyle={styles.TextStyle}
          modalContentContainerStyle={{
            backgroundColor: "#777",
          }}
          itemSeparatorStyle={{
            color: "#fff",
            width: "100%",
          }}
          labelProps={{
            value: "VALUE",
          }}
          theme="DARK"
        />
      );
    };

    let jsx = [];
    let fieldNameKeys = Object.keys(fieldNames);
    for (let i = 2; i < fieldNameKeys.length; i++) {
      let key = fieldNameKeys[i];
      jsx.push(
        <View style={styles.SectionStyle} key={key}>
          <Text style={styles.TextStyle}>{fieldNames[key] + ":"}</Text>

          <TextInput
            style={styles.inputStyle}
            value={formData[key]}
            secureTextEntry={key === "password" ? true : false}
            maxLength={key === "pin" ? 4 : undefined}
            keyboardType={key === "pin" ? "numeric" : undefined}
            onChangeText={(input) => {
              if (key !== "pin" || /^\d+$/.test(input) || input === "") {
                setFormData({ ...formData, [key]: input });
              }
            }}
            require="true"
            underlineColorAndroid="#f000"
            placeholder={"Your " + fieldNames[key]}
            placeholderTextColor="#8b9cb5"
            autoCapitalize="sentences"
            returnKeyType="next"
            onSubmitEditing={Keyboard.dismiss}
            blurOnSubmit={false}
          />
        </View>
      );
    }

    return isRegistrationSuccess ? (
      <View
        style={{
          flex: 1,
          backgroundColor: "#000",
          justifyContent: "center",
        }}
      >
        <Image
          source={require("../assets/success.png")}
          style={{
            height: 150,
            resizeMode: "contain",
            alignSelf: "center",
          }}
        />
        <Text style={styles.successTextStyle}>Registration Successful</Text>
        <TouchableOpacity
          style={styles.buttonStyle}
          activeOpacity={0.5}
          onPress={async () => {
            console.log(formData.username);
            console.log(formData.password);
            await ioHelper.storeData("user_name", formData.username);
            await ioHelper.storeData("user_password", formData.password);
            props.navigation.navigate("LoginScreen");
          }}
        >
          <Text style={styles.buttonTextStyle}>Login Now</Text>
        </TouchableOpacity>
      </View>
    ) : loading ? (
      <Loader />
    ) : (
      <KeyboardAvoidingView
        enabled
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
      >
        {deviceType === "web" ? (
          <>
            <View style={styles.SectionStyle}>
              <PickerWeb
                type={fieldNames.clientName}
                selected={formData.clientName}
                setSelected={(clientName) =>
                  setFormData({ ...formData, clientName })
                }
                contents={clientNames}
              />
            </View>
            <View style={styles.SectionStyle}>
              <PickerWeb
                type={fieldNames.department}
                selected={formData.department}
                setSelected={(department) =>
                  setFormData({ ...formData, department })
                }
                contents={departments}
              />
            </View>
          </>
        ) : (
          <>
            <View style={styles.SectionStyle}>
              <PickerAndroid
                open={openClientNamesDropdown}
                value={formData.clientName}
                placeholder={`Select Your ${fieldNames.clientName}`}
                items={clientNames.map((name) => {
                  return { label: name, value: name };
                })}
                setOpen={setOpenClientNamesDropdown}
                setValue={(clientNameFunc) =>
                  setFormData({ ...formData, clientName: clientNameFunc() })
                }
                setItems={setClientNames}
              />
            </View>
            <View style={styles.SectionStyle}>
              <PickerAndroid
                open={openDepartmentsDropdown}
                value={formData.department}
                placeholder={`Select Your ${fieldNames.department}`}
                items={departments.map((department) => {
                  return { label: department, value: department };
                })}
                setOpen={setOpenDepartmentsDropdown}
                setValue={(departmentFunc) =>
                  setFormData({ ...formData, department: departmentFunc() })
                }
                setItems={setDepartments}
              />
            </View>
          </>
        )}
        {jsx}
        {errortext != "" ? (
          <Text style={styles.errorTextStyle}>{errortext}</Text>
        ) : null}
        <TouchableOpacity
          style={styles.buttonStyle}
          activeOpacity={0.5}
          onPress={handleSubmitButton}
        >
          <Text style={styles.buttonTextStyle}>REGISTER</Text>
        </TouchableOpacity>
      </KeyboardAvoidingView>
    );
  };

  const [deviceId, setDeviceId] = useState("");
  const [deviceType, setDeviceType] = useState(null);
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);

  React.useEffect(() => {
    setDeviceId(Application.androidId);

    async function retrieveDType() {
      const dType = await ioHelper.retrieveData("device_type");
      setDeviceType(dType);
      console.log("DEVICETYPE:", dType);
    }

    retrieveDType();
  }, [deviceId, deviceType, setDeviceType]);

  return (
    <View style={{ flex: 1, backgroundColor: "#000" }}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <View style={{ alignItems: "center" }}>
          <Image
            source={require("../assets/ctrlrobotics.png")}
            style={{
              width: "70%",
              height: 130,
              resizeMode: "contain",
              marginTop: 30,
            }}
          />
        </View>

        <FormFields
          isRegistrationSuccess={isRegistrationSuccess}
          setIsRegistrationSuccess={setIsRegistrationSuccess}
        />
      </ScrollView>
    </View>
  );
}

export default RegisterScreen;

const styles = StyleSheet.create({
  SectionStyle: {
    flexDirection: "column",
    height: 80,
    marginLeft: "20%",
    marginRight: "20%",
    margin: 5,
  },
  TextStyle: {
    color: "white",
    fontWeight: "bold",
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: "#0466c8",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: 20,
    marginBottom: 20,
  },
  buttonTextStyle: {
    color: "#FFFFFF",
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: "white",
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: "#dadae8",
  },
  errorTextStyle: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
  successTextStyle: {
    color: "white",
    textAlign: "center",
    fontSize: 18,
    padding: 30,
  },
});
