import React from 'react'
import {
    View, ScrollView,
    StyleSheet, Modal, Pressable, TextInput, Text
} from 'react-native';
import { Picker } from '@react-native-picker/picker';

import { List, Button, Surface } from 'react-native-paper';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import Loader from '../Components/Loader3';

import RobotsContext from '../Context/robotsContext'

// import mixpanel from '../../MixPanel';

import ioHelper from '../Helpers/ioHelper';
import { createTicketFromTaskStatusChange, sendDataToFleetController } from '../Helpers/apiHelper';


const Tab = createMaterialTopTabNavigator();


function firstCaps(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}


function DeliveryScreen({ navigation }) {

    // COMPONENTS
    const AccordionContents = ({ task, index }) => {


        const WardBoxContents = () => {
            return (
                <Surface
                    style={[styles.bContainer, {
                        display: 'flex', flexDirection: 'column', flex: 1,
                    }]}
                >


                    {/** Headings */}
                    <View
                        style={{
                            borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'row', flex: 1,
                            justifyContent: 'flex-start', alignItems: 'space-between',
                            paddingBottom: 5,
                        }}
                    >


                        <View
                            style={{
                                borderColor: 'green', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-start', alignItems: 'center',

                            }}
                        >

                            <Text style={[styles.bodyText, {

                                borderRadius: 5, backgroundColor: "#fff",
                                padding: 5,
                                textTransform: 'uppercase',
                            }]}>
                                Ward
                            </Text>



                        </View>



                        <View
                            style={{
                                borderColor: 'green', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'center', alignItems: 'center',
                            }}
                        >

                            <Text style={[styles.bodyText, {

                                borderRadius: 5, backgroundColor: "#fff",
                                padding: 5,
                                textTransform: 'uppercase',
                            }]}>
                                Box
                            </Text>


                        </View>



                        <View
                            style={{
                                borderColor: 'green', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-end', alignItems: 'center'

                            }}
                        >


                            <Text style={[styles.bodyText, {

                                borderRadius: 5, backgroundColor: "#fff",
                                padding: 5,
                                textTransform: 'uppercase',
                            }]}
                            >
                                Contents
                            </Text>


                        </View>


                    </View>


                    {/** List */}
                    {task.scripts.map((scriptsForWard, index) => {

                        return (
                            <View
                                key={`scrpt-${index}`}
                                style={{
                                    borderColor: 'black', borderStyle: 'solid', borderWidth: 0,
                                    borderTopWidth: 0.7,
                                    display: 'flex', flexDirection: 'row', flex: 1,
                                    justifyContent: 'flex-start', alignItems: 'center',
                                    paddingTop: 5, paddingHorizontal: 5,

                                }}
                            >


                                <View
                                    style={{
                                        borderColor: 'green', borderStyle: 'solid', borderWidth: 0,
                                        display: 'flex', flexDirection: 'row', flex: 1,
                                        justifyContent: 'flex-start', alignItems: 'center',
                                    }}
                                >

                                    {task.assignee.robotId === "unassigned" ? (
                                        <Text style={[styles.bodyText, { textAlign: 'left' }]}>
                                            {`${task.assignee?.wardValues?.[index]}`}
                                        </Text>
                                    ) : (
                                        <Text style={[styles.bodyText, { textAlign: 'left' }]}>
                                            {`${task.assignee.destinationQueue[index + 1]}`}
                                        </Text>
                                    )}



                                </View>



                                <View
                                    style={{
                                        borderColor: 'green', borderStyle: 'solid', borderWidth: 0,
                                        display: 'flex', flexDirection: 'row', flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}
                                >

                                    <Text style={[styles.bodyText, { textAlign: 'center' }]}>
                                        {`${task.assignee.shelves[index].join("\n")}`}
                                    </Text>


                                </View>



                                <View
                                    style={{
                                        borderColor: 'green', borderStyle: 'solid', borderWidth: 0,
                                        display: 'flex', flexDirection: 'row', flex: 1,
                                        justifyContent: 'flex-end', alignItems: 'center',

                                    }}
                                >


                                    <Text style={[styles.bodyText, { textAlign: 'right' }]}>
                                        {`${task.scripts[index].join("\n")}`}
                                    </Text>


                                </View>


                            </View>
                        )
                    })}






                </Surface>
            )
        }

        const QueueControlButtons = ({ task, index }) => {

            return (
                <View
                    style={
                        [styles.gContainer, {
                            flex: 0.2,
                        }]
                    }
                >

                    <View
                        style={{
                            borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'row', flex: 1,
                            justifyContent: 'center', alignItems: 'center',

                        }}
                    >


                        <Button style={[styles.button, {
                            backgroundColor: "#ff0000",
                        }]}
                            color="blue"
                            onPress={() => handleDropped(task, index)}
                            disabled={droppedBtnDisabled}
                        >
                            <Text style={styles.buttonText}>
                                Drop
                            </Text>
                        </Button>


                        <Button style={[styles.button, {
                            backgroundColor: "#0000ff"
                        }]}
                            color="blue"
                            onPress={() => handleRetry(task)}
                            disabled={retryBtnDisabled}

                        >
                            <Text style={styles.buttonText}>
                                Retry
                            </Text>
                        </Button>


                    </View>



                </View>
            )
        }

        const DeliveryId = () => {
            return (
                <View
                    style={[styles.gContainer]}
                >


                    <View
                        style={{
                            borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'row', flex: 1,
                            justifyContent: 'flex-start', alignItems: 'center',
                        }}
                    >
                        <Text style={styles.bodyText}>
                            Delivery ID
                        </Text>
                    </View>



                    <View
                        style={{
                            borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'row', flex: 0.7,
                            justifyContent: 'flex-end', alignItems: 'center',
                        }}
                    >


                        <Surface
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                borderRadius: 5, backgroundColor: '#ccc',
                                display: 'flex', flexDirection: 'row', flex: 0.7,
                                justifyContent: 'center', alignItems: 'center'

                            }}
                        >
                            <Text style={[styles.bodyText, {
                                justifyContent: 'center', flex: 1,
                                padding: 3,
                                textAlign: 'center',
                            }]}>
                                {task.shortId ? task.shortId : task._id.substr(task._id.length - 9)}
                            </Text>
                        </Surface>

                    </View>




                </View>
            )
        }

        const AssignedRobot = () => {
            return (
                <Surface
                    style={
                        styles.bContainer
                    }
                >


                    <View
                        style={{
                            borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'row', flex: 1,
                            justifyContent: 'flex-start', alignItems: 'center'
                        }}
                    >


                        <Text style={styles.bodyText}>
                            Robot:
                        </Text>


                    </View>


                    <View
                        style={{
                            borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'row', flex: 1,
                            justifyContent: 'flex-end', alignItems: 'center'
                        }}
                    >


                        <Text style={styles.bodyText}>
                            {task.assignee.alias}
                        </Text>


                    </View>




                </Surface>
            )
        }

        const ShelfButtons = () => {
            return (
                <>

                    <View
                        style={styles.gContainer}
                    >


                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-start', alignItems: 'center'
                            }}
                        >


                            <Text style={styles.bodyText}>
                                Box 1:
                            </Text>


                        </View>


                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1.5,
                                justifyContent: 'flex-end', alignItems: 'center'
                            }}
                        >


                            <Button style={[styles.button, {
                                backgroundColor: "red"
                            }]}
                                onPress={() => handleShelf("1", "lock", task)}
                            >
                                <Text style={styles.buttonText}>
                                    Lock
                                </Text>
                            </Button>


                        </View>


                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-end', alignItems: 'center'

                            }}
                        >

                            <Button style={[styles.button, {
                                backgroundColor: "green"
                            }]}
                                onPress={() => handleShelf("1", "unlock", task)}
                            >
                                <Text style={styles.buttonText}>
                                    Unlock
                                </Text>
                            </Button>

                        </View>


                    </View>







                    {/** Shelf 2 */}
                    <View
                        style={
                            styles.gContainer
                        }
                    >


                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-start', alignItems: 'center'
                            }}
                        >


                            <Text style={styles.bodyText}>
                                Box 2:
                            </Text>


                        </View>


                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1.5,
                                justifyContent: 'flex-end', alignItems: 'center'
                            }}
                        >


                            <Button style={[styles.button, {

                                backgroundColor: "red"
                            }]}
                                onPress={() => handleShelf("2", "lock", task)}
                            >
                                <Text style={styles.buttonText}>
                                    Lock
                                </Text>
                            </Button>


                        </View>



                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-end', alignItems: 'center'

                            }}
                        >

                            <Button style={[styles.button, {

                                backgroundColor: "green"
                            }]}
                                onPress={() => handleShelf("2", "unlock", task)}
                            >
                                <Text style={styles.buttonText}>
                                    Unlock
                                </Text>
                            </Button>

                        </View>


                    </View>

                </>
            )
        }

        const DestinationQueue = () => {
            return (
                <Surface
                    style={
                        styles.bContainer
                    }
                >


                    {/* <View
                        style={{
                            borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'row', flex: 1,
                            justifyContent: 'flex-start', alignItems: 'center',
                        }}
                    >


                        <Text style={[styles.bodyText,
                        ]}>
                            Destination Queue:
                        </Text>


                    </View> */}


                    <View
                        style={{
                            borderColor: 'yellow', borderStyle: 'solid', borderWidth: 0,
                            display: 'flex', flexDirection: 'column', flex: 1,
                            justifyContent: 'center', alignItems: 'center',
                            minHeight: 60,
                        }}
                    >


                        {task.assignee.destinationQueue.map((dq, i) => {
                            return (
                                <View key={`dq-${i}`}
                                    style={{
                                        borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                        display: 'flex', flexDirection: 'row', flex: 1,
                                        justifyContent: 'flex-end', alignItems: 'center',
                                        padding: 5, minHeight: 20,
                                    }}
                                >

                                    <Text style={[styles.bodyText, { textAlign: 'right' }]}>
                                        {(task.assignee.destinationIndex === i && (task.taskStatus !== "pending" && task.taskStatus !== "queued")
                                        ) ? `> ${firstCaps(dq)}` : firstCaps(dq)}
                                    </Text>


                                </View>
                            )
                        })}

                    </View>


                </Surface>
            )
        }

        const DispatchedBy = () => {
            {
                task.assignee.dispatchedBy ? (
                    <Surface
                        style={
                            [styles.bContainer]

                        }
                    >


                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-start', alignItems: 'center'
                            }}
                        >


                            <Text style={styles.bodyText}>
                                Dispatched by:
                            </Text>


                        </View>


                        <View
                            style={{
                                borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
                                display: 'flex', flexDirection: 'row', flex: 1,
                                justifyContent: 'flex-end', alignItems: 'center'
                            }}
                        >


                            <Text style={styles.bodyText}>
                                {`${task.assignee.dispatchedBy}`}
                            </Text>


                        </View>




                    </Surface>
                ) : (
                    null
                )
            }
        }

        return (
            <View
                style={{
                    borderColor: 'red', borderStyle: 'solid', borderWidth: 0,
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 10,
                }}
            >



                {/* <WardBoxContents /> */}


                <DestinationQueue />


                <QueueControlButtons task={task} index={index} />


                {/* <AssignedRobot /> */}



                {/* <DispatchedBy/> */}













            </View>
        )
    }

    const TaskStatusIndicator = ({ task }) => {

        // We want to know if some task went from "processing..." to "rejected"
        React.useEffect(() => {
            // Store a ref if at any point a task was "processing..."
            if (task.taskStatus === "processing...") prevTaskRef.current = task;
        }, [task]);
        // console.log(task?.taskStatus, prevTaskRef.current?.taskStatus)
        const processingToRejected = task.taskStatus.toLowerCase().includes("rejected") && (prevTaskRef.current?.taskStatus === "processing...") && task.assignee.rejectionReason === undefined
        if (processingToRejected) {
            // Reset ref for the next task and create a ticket
            prevTaskRef.current = null;
            createTicketFromTaskStatusChange(task).catch(e => console.error(e));
        }

        return (
            <View>
                <View>
                    <Text style={[styles.bodyText]}>
                        {task.shortId ? task.shortId : task._id.substr(task._id.length - 9)}
                    </Text>
                </View>
                <View>
                    <Text style={
                        task.taskStatus === "pending"
                            ? { color: "green" }
                            : task.taskStatus === "in-progress"
                                ? { color: "red" }
                                : (task.taskStatus === "arrived" && prevTaskRef.current?.taskStatus !== "offloading")
                                    ? { color: "green" }
                                    : task.taskStatus === "returning"
                                        ? { color: "red" }
                                        : task.taskStatus === "rejected"
                                            ? { color: "white" }
                                            : { color: "blue" }
                    }>
                        {prevTaskRef.current?.taskStatus === "offloading" ? "Offloading" : (
                            task.taskStatus === "rejected"
                                ? firstCaps(task.assignee.rejectionReason ? task.assignee.rejectionReason : "Rejected")
                                : task.taskStatus === "failed"
                                    ? firstCaps(task.assignee.failedReason ? task.assignee.failedReason : "Failed")
                                    : firstCaps(task.taskStatus)
                        )}
                    </Text>

                </View>
            </View>
        )



    }



    // STATES
    const isMounted = React.useRef(null);       // Use effect cleanup flag
    const [snackbarVisible, setSnackbarVisible] = React.useState(false);
    const [snackbarContent, setSnackbarContent] = React.useState({
        title: "",
        message: "",
    })
    const [tasksArray, setTasksArray] = React.useState([]);
    const [robotsArray, setRobotsArray] = React.useState([]);
    const [clickedIndex, setClickedIndex] = React.useState(null);

    const robotsContext = React.useContext(RobotsContext);


    // For the loading animations and button controls
    const [retryBtnDisabled, setRetryBtnDisabled] = React.useState(false);
    const [droppedBtnDisabled, setDroppedBtnDisabled] = React.useState(false);
    const [fetchingRobotStates, setFetchingRobotStates] = React.useState(false);

    // Announcement data
    React.useEffect(() => {
        // executed on component mount
        isMounted.current = true;

        let c = 0;
        const retryCount = 5;
        

        if (isMounted.current === true) loadAnnouncementsData();

        async function loadAnnouncementsData() {
            const start = Date.now();

            const client = robotsContext.client;
            const clientId = await ioHelper.retrieveData("client_id")

            // Get robots belonging to clientId
            let announcementsPromise = client.service("announcements").find({
                query: {
                    $limit: 1000,
                    $sort: {
                        createdAt: -1
                    },
                    'taskStatus': { $nin: ["dropped", "completed", "cancelled"] },
                    'clientId': clientId,
                }
            });

            let robotsPromise = client.service("robots").find({
                query: {
                    $limit: 1000,
                    'clientId': clientId,
                }
            });

            setSnackbarContent({
                ...snackbarContent,
                message: "Refreshing content"
            })
            setSnackbarVisible(true);

            let [announcements, robots] = await Promise.all([announcementsPromise, robotsPromise])    // Resolved
            console.log("ANNOUNCEMENTS", announcements.data);
            let tasks = [];
            announcements.data.forEach((ann, index) => {
                tasks.push(ann)
            })
            tasks = tasks.filter(task => {
                return task.taskStatus !== "dropped" && task.taskStatus !== "completed" && task.taskStatus !== "cancelled";
            })
            setTasksArray(tasks.reverse())

            console.log("ROBOTS", robots);
            setRobotsArray(robots.data);

            setSnackbarContent({
                ...snackbarContent,
                message: "Done"
            })
            setSnackbarVisible(true);

            // console.log("elapsed @LoadScreen.loadAnnouncementsData:", Date.now() - start)
        };


        return () => {
            // executed on component unmount
            isMounted.current = false;
        }
    }, [robotsContext])


    // For the task StatusIndicator
    const prevTaskRef = React.useRef();



    // FUNCTIONS

    // const setMixPanel = async props => {
    //     mixpanel.identify(await ioHelper.retrieveData("user_name"));
    //     mixpanel.track(props);
    // }

    // For the buttons
    const handleRetry = async (task) => {
        console.log("RETRY", task._id);
        setSnackbarContent({
            ...snackbarContent,
            message: "Processing task"
        });
        setSnackbarVisible(true);

        // const { canRetry, availableConnections } = await checkRobotsConnections(task);

        // console.log("canRetry", canRetry)

        // if (!canRetry) {
        //     alert("Cannot begin task. First make sure that there is an internet connection on at least one of the following robots, and then retry:\n\n" + availableConnections.map(c => c.robot.name).join("\n"));
        //     return;
        // }

        robotsContext.client.service("announcements").patch(task._id, {
            taskStatus: "queued"
        }).then((r) => {
            setRetryBtnDisabled(false);
            setSnackbarContent({
                ...snackbarContent,
                message: "Queued"
            });
            setSnackbarVisible(true);
        }).catch(err => {
            setRetryBtnDisabled(false);
            alert("Error queueing task.");
            console.error("Error @handleQueue: " + err);
            navigation.replace("Auth");
        });

    };

    const checkRobotsConnections = async (task) => {
        console.log("Checking free robot connections");
        setFetchingRobotStates(true);
        setRetryBtnDisabled(true);
        setDroppedBtnDisabled(true);
        let canRetry;
        let availableConnections = []


        try {

            const clientId = await ioHelper.retrieveData("client_id");
            const body = {
                command: "getRobotStates",
                data: {
                    clientId: clientId,
                },
            }
            const { robotStates } = await sendDataToFleetController("robots", body);
            console.log({ robotStates })
            const connections = robotStates.map(x => ({
                robot: {
                    name: x.robotName,
                    type: x.robotType,
                },
                connection: x.robotConnection,
                id: x.robotId,
            }))
            for (let i = 0, n = connections.length; i < n; i++) {
                const c = connections[i];
                const id = c.id;
                const ann = (await robotsContext.client.service("announcements").find({
                    query: {
                        "clientId": clientId,
                        "assignee.robotId": id,
                        $limit: 1,
                        $sort: {
                            createdAt: -1,
                        },
                    },
                })).data[0];
                const taskStatus = ann?.taskStatus;
                console.log(taskStatus, id, ann?.shortId);
                const robotBusy = ["pending", "in-progress", "dispatched", "dispatching", "offloaded", "offloading", "returning"].includes(taskStatus);
                if (!robotBusy) availableConnections.push(c);
            }
            console.log({ availableConnections })

            for (let i = 0, n = availableConnections.length; i < n; i++) {
                const c = availableConnections[i];
                const { robot, connection } = c
                if (!robot || !connection) continue;
                const { socket, openApi } = connection;
                const { type } = robot;

                console.log({ socket, openApi, type })

                if (
                    (socket === "online" && openApi === "online")
                    || (socket === "online" && type === "Virtual")
                ) {
                    canRetry = true;
                    break;
                }
            }

            setFetchingRobotStates(false);
            setRetryBtnDisabled(false);
            setDroppedBtnDisabled(false);

            return { canRetry, availableConnections };

        } catch (e) {
            console.error("Caught at checkRobotsConnections", e);
            setFetchingRobotStates(false);
            setRetryBtnDisabled(false);
            setDroppedBtnDisabled(false);
            return { canRetry, availableConnections };
        }


    }

    const handleDropped = async (task, index) => {
        console.log("DROP", task._id);
        setSnackbarContent({
            ...snackbarContent,
            message: "Dropping task"
        });
        setSnackbarVisible(true);
        setDroppedBtnDisabled(true);


        const cancelled = checkUserCancellation(task);
        const completed = checkDeliveryCompleted(task);
        if (cancelled) {
            handleCancelled(task);
            setDroppedBtnDisabled(false);
            return;
        } else if (completed) {
            handleCompleted(task)
            setRetryBtnDisabled(false); 
            return;
        }
        else {
            showModal(index);
            return;
        }

    };

    const checkUserCancellation = (task) => {
        let cancelledTask = false;
        const { timestamps } = task;

        if (
            !timestamps["dispatched"]
        ) {
            cancelledTask = true;
        }

        return cancelledTask;
    }

    const checkDeliveryCompleted = (task) => {
        const { assignee } = task;

        try {
            const { destinationQueue, receivedBy } = assignee;
            if (
                (destinationQueue.length - 2) - Object.keys(receivedBy).length === 0
            ) {
                return true;
            } else {
                return false
            }

        } catch (e) {
            console.error(e);
            return false;
        }

    }

    const handleCancelled = async (task) => {
        console.log("CANCELLED", task._id);
        setSnackbarContent({
            ...snackbarContent,
            message: "Cancelling task"
        });
        setSnackbarVisible(true);
        robotsContext.client.service("announcements").patch(task._id, {
            taskStatus: "cancelled"
        }).then((r) => {
            console.log(r);
            setSnackbarContent({
                ...snackbarContent,
                message: "Cancelled"
            });
            setSnackbarVisible(true);
        }).catch(err => {
            alert("Error cancelling task. Refresh the page and try again.");
            console.error("Error @handleCancelled: " + err);
            // navigation.replace("Auth");
        });

    };

    const handleCompleted = async (task) => {
        console.log("COMPLETE", task._id);
        setSnackbarContent({
            ...snackbarContent,
            message: "Completing task"
        });
        setSnackbarVisible(true);
        robotsContext.client.service("announcements").patch(task._id, {
            taskStatus: "completed"
        }).then((r) => {
            console.log(r);
            setSnackbarContent({
                ...snackbarContent,
                message: "Completed"
            });
            setSnackbarVisible(true);
        }).catch(err => {
            alert("Error completing task.");
            console.error("Error @handleCompleted: " + err);
        });

    };

    const handleShelf = async (which, action, task) => {
        let cmd = "2";
        switch (action) {
            case "lock":
                cmd += "1"
                break;
            case "unlock":
                cmd += "0"
                break;
        }
        switch (which) {
            case "1":
                cmd += "1"
                break;
            case "2":
                cmd += "2"
                break;
        }

        try {
            const rColId = task.assignee.rootCollection;
            const rDocId = (
                await robotsContext.client.service("collections").get(rColId)
            ).documents.rootDocument.split('.')[2];

            robotsContext.client.service("documents").patch(rDocId,
                {
                    fieldValues: {
                        type: "root",
                        data: {
                            command: +cmd,
                        }
                    }
                }
            );
        } catch (e) {
            console.error(e)
        }

    }



    // Dropped dialog
    const showModal = (index) => {
        console.log({ index })
        setDroppedBtnDisabled(false);
        setClickedIndex(index);
        setModalVisible(true);
    }
    const hideModal = () => {
        setModalVisible(false);
    }

    // Modal state 
    const dropDownItems = {
        issueTypes: [
            '',
            'Arrived at pharmacy but status not completed',
            'Reconnecting at ward',
            'Reconnecting at Pharmacy',
            'Box did not open at ward',
            'Box did not open at Pharmacy',
            'Pressed SEND button but robot did not move',
            'Robot got stuck',
        ]
    }

    const initialFormData = {
        "Select reason": dropDownItems.issueTypes[0],
        "PIN": "",
        "Or enter description": "",
    }
    const [modalForm, setModalForm] = React.useState(initialFormData)
    const [modalVisible, setModalVisible] = React.useState(false);

    const [dropSubmitBtnDisabled, setDropSubmitBtnDisabled] = React.useState(false)



    return (
        <>

            <ScrollView
                style={styles.rootScrollView}
                nestedScrollEnabled={true}
            >

                <List.AccordionGroup>

                    {tasksArray.length > 0 ? (
                        tasksArray.map((task, index) => {
                            return (
                                <List.Accordion

                                    id={`accordion-${index}`}
                                    key={`accordion-${index}`}
                                    style={task.taskStatus === "rejected" ? (
                                        {
                                            marginTop: 5,
                                            backgroundColor: '#444'
                                        }
                                    ) : (
                                        {
                                            marginTop: 5,
                                            backgroundColor: '#eee'
                                        }
                                    )}

                                    left={props => <TaskStatusIndicator {...props} task={task} />}

                                    right={() => (

                                        task.robotId ? (
                                            <Text>
                                                {robotsArray.find(robot => robot.robotId === task.robotId)?.robotMeta?.alias}
                                            </Text>
                                        ) : null

                                    )}

                                >

                                    <AccordionContents task={task} index={index} />

                                    <Loader
                                        loading={fetchingRobotStates}
                                    />

                                </List.Accordion>
                            )
                        })
                    ) : (
                        <Text style={[styles.bodyText, { margin: 10 }]}>
                            Delivery queue is empty. Use the Demand screen to add new deliveries.
                        </Text>
                    )}



                </List.AccordionGroup>


                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={hideModal}>

                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>






                            <View style={[styles.mView, { marginBottom: 20, }]}>
                                <Text style={[styles.bodyText, styles.headText]}>
                                    Drop task
                                </Text>
                            </View>





                            <View style={[styles.mView]}>
                                {NewField("Select reason", dropDownItems.issueTypes)}
                            </View>





                            <View style={[styles.mView, { flex: 1.6, }]}>
                                {NewField("Or enter description")}
                            </View>




                            <View style={[styles.mView]}>
                                {NewField("PIN", dropDownItems.dispatchers)}
                            </View>





                            <View style={[styles.mView, { justifyContent: 'flex-end' }]}>

                                <ModalButtons />

                            </View>









                        </View>
                    </View>


                </Modal>


            </ScrollView>


        </>
    )






    // DEPENDENT COMPONENTS 

    function NewField(fieldName, items = []) {
        const [index, setIndex] = React.useState(0);
        const picker = items.length > 0;
        return <>
            <View style={[styles.nViewLeft]}>
                <Text style={[styles.modalText]}> {fieldName} </Text>
            </View>

            {picker ? (
                <View style={[styles.nViewRight]}>
                    <Picker
                        style={[styles.picker]}
                        selectedValue={items[index]}
                        onValueChange={(itemValue, itemIndex) => {
                            setIndex(itemIndex)
                            setModalForm({
                                ...modalForm,
                                [fieldName]: itemValue,
                            })
                        }
                        }>
                        {items.map((itemName) => (
                            <Picker.Item key={itemName} label={itemName} value={itemName} />
                        )
                        )}
                    </Picker>
                </View>
            ) : (
                <View style={[styles.nViewRight,]}>
                    <TextInput style={[styles.textInput, (fieldName === "Or enter description" ? ({ height: 70 }) : null)]}
                        multiline={fieldName === "Or enter description"}
                        maxLength={fieldName === "PIN" ? 4 : null}
                        onChangeText={(str) => {
                            setModalForm({
                                ...modalForm,
                                [fieldName]: str,
                            });
                        }}
                        value={modalForm[fieldName]}
                        placeholder={
                            fieldName === "PIN" ? (
                                `Enter your PIN`
                            ) : (
                                fieldName === "Email address" ? (
                                    `Enter your email address`
                                ) : (
                                    fieldName === "Title" ? (
                                        `Enter a short title for the problem`
                                    ) : (
                                        fieldName === "Or enter description" ? (
                                            `Describe the problem in detail`
                                        ) : (
                                            `useless placeholder`
                                        )
                                    )
                                )
                            )
                        }
                    />
                </View>
            )}
        </>;
    }

    function ModalButtons() {

        return (
            <>
                <Pressable
                    style={[styles.button, styles.buttonClose]}
                    onPress={() => setModalVisible(!modalVisible)}>
                    <Text style={[styles.bodyText, styles.buttonText]}>Close</Text>
                </Pressable>

                <Pressable
                    style={[styles.button, styles.buttonSubmit]}
                    disabled={dropSubmitBtnDisabled}
                    onPress={async () => {

                        if (!modalForm.PIN) {
                            alert("Please enter PIN.");
                            return;
                        }
                        if (
                            !modalForm['Select reason']
                            && !modalForm['Or enter description']
                        ) {
                            alert("Please select reason or enter description.");
                            return;
                        }

                        const formData = {
                            droppedReason: modalForm['Or enter description'] ? modalForm['Or enter description'] : modalForm["Select reason"],
                            droppedRequestorPin: modalForm["PIN"],
                        }

                        const task = tasksArray[clickedIndex];

                        console.log({ formData, task })

                        // Validate PIN
                        const postData = {
                            command: "getUserAliasFromPin",
                            data: {
                                clientId: task.clientId,
                                pin: formData.droppedRequestorPin,
                            }
                        }
                        const res = await sendDataToFleetController("clients", postData);
                        if (res.status === 200) {
                            setDropSubmitBtnDisabled(true);
                            robotsContext.client.service("announcements").patch(task._id, {
                                taskStatus: "dropped",
                                "assignee.droppedReason": formData.droppedReason,
                            }).then((r) => {
                                // setMixPanel('Dropped Task');
                                setDroppedBtnDisabled(false);
                                setDropSubmitBtnDisabled(false);
                                setSnackbarContent({
                                    ...snackbarContent,
                                    message: "Dropped"
                                });
                                setSnackbarVisible(true);
                                hideModal();
                            }).catch(err => {
                                setDroppedBtnDisabled(false);
                                alert("Error dropping task. Refresh the page and try again.");
                                console.error("Error @handleDropped: " + err);
                            });
                        } else {
                            console.error({ res })
                            alert("Incorrect PIN. Cannot drop task.");
                            return;
                        }





                    }}>
                    <Text style={[styles.bodyText, styles.buttonText]}>Submit</Text>
                </Pressable>
            </>
        )
    }







}


export default DeliveryScreen


let styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 24,
        backgroundColor: "#eaeaea"
    },
    tableCell: {
        borderColor: "red",
        borderWidth: 0,
        justifyContent: 'center',
        flex: 1,
    },

    rootSafeArea: { flex: 1, backgroundColor: '#555' },
    rootScrollView: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        flex: 1,
        backgroundColor: "#fff"
    },
    headTextContainer: {
        borderWidth: 1,
        borderColor: "red",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    headTextView: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        marginLeft: 10,
        marginRight: 10,
        position: 'relative'
    },
    headText: {
        color: "#ccc",
        fontSize: 18,
    },
    surface: {
        borderWidth: 0,
        borderColor: "red",
        borderStyle: "solid",
        backgroundColor: "#222",
        justifyContent: 'center',
        marginBottom: 0,
        flex: 1,
        padding: 10,
        elevation: 10,
        borderRadius: 0,
    },
    surfaceView: {
        borderWidth: 0,
        borderColor: "blue",
        borderStyle: "solid",
        padding: 10,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bodyText: {
        color: "#555",
        fontSize: 18,
        margin: 3,
    },
    surfaceChild: {
        borderWidth: 0,
        borderColor: "black",
        borderStyle: "solid",
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        position: 'relative',
        backgroundColor: "#555"
    },



    buttonText: {
        color: '#f0f0f0',
        fontSize: 16,
        textTransform: "uppercase",
    },
    button: {
        textAlign: 'center',
        backgroundColor: '#444',
        borderWidth: 1,
        color: '#FFFFFF',
        borderRadius: 5,
        // minWidth: 50,
        marginHorizontal: 2,

        borderColor: 'blue', borderStyle: 'solid', borderWidth: 0,
        display: 'flex', flexDirection: 'row', flex: 1,
        justifyContent: 'center', alignItems: 'center',
    },

    gContainer: {
        borderColor: 'green', borderStyle: 'solid', borderWidth: 0,
        display: 'flex', flexDirection: 'row', flex: 1,
        marginVertical: 8,
        justifyContent: 'flex-start',
    },
    bContainer: {
        borderColor: 'black', borderStyle: 'solid', borderWidth: 0,
        borderRadius: 5, backgroundColor: "#fff",
        paddingHorizontal: 4, paddingVertical: 4,
        marginVertical: 5,
        display: 'flex', flexDirection: 'row', flex: 1,
        justifyContent: 'center',
        elevation: 12,
        minHeight: 100,
        overflow: 'scroll'
    },





    buttonOpen: {
        backgroundColor: '#fff',
        borderWidth: 0, borderStyle: 'solid', borderColor: 'black',
    },
    buttonClose: {
        backgroundColor: '#555',
        color: '#fff',
    },
    buttonSubmit: {
        backgroundColor: '#1900D8',
        color: '#fff',
    },

    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {

        borderColor: 'blue', borderWidth: 0, borderStyle: 'solid',

        margin: 20,
        backgroundColor: '#222',
        borderRadius: 8,
        padding: 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalText: {
        textAlign: 'center',
        color: 'white',
    },

    mView: {
        borderColor: 'green', borderWidth: 0, borderStyle: 'solid',
        flex: 1, flexDirection: 'row',
        justifyContent: 'flex-start', alignItems: 'center',

        padding: 5,

        minWidth: 450, minHeight: 50,
    },
    nViewLeft: {
        borderColor: 'blue', borderWidth: 0, borderStyle: 'solid',
        flex: 0.5, flexDirection: 'row',
        justifyContent: 'flex-start', alignItems: 'center',

        padding: 5,
        minHeight: 50,
    },
    nViewRight: {
        borderColor: 'blue', borderWidth: 0, borderStyle: 'solid',
        flex: 1, flexDirection: 'row',
        justifyContent: 'flex-start', alignItems: 'center',

        padding: 5,
        minHeight: 50,
    },


    textInput: {
        height: 40,
        borderWidth: 1,
        padding: 10,
        color: 'white',
        borderColor: 'white',

        flex: 1,
        borderRadius: 6,
    },

    picker: {
        flex: 1,
        height: 40,
        borderRadius: 8,
        backgroundColor: '#eee'
    }





});