// Example of Splash, Login and Sign Up in React Native
// https://aboutreact.com/react-native-login-and-signup/

// Import React and Component
import React from 'react';
import { StyleSheet, View, Modal, Platform, Image, Text} from 'react-native';

import { ActivityIndicator, Colors } from 'react-native-paper';

const Loader = (props) => {
  const { loading, ...attributes } = props;

  return (
    <>

      {/** Gif */}
      <View 
      style={[styles.activityIndicator, {opacity: +loading}]}>
        <Text> Looking for robots. Please wait... </Text>
        <Image
          style={Platform.OS === 'web' ? (
            { width: 300, height: 300 }
          ) : (
            { width: 180, height: 180 }
          )}
          source={require('../../assets/60796-robohead-loading.gif')} />
      </View>

    </>
  );
};

export default Loader;

const styles = StyleSheet.create({
  // modalBackground: {
  //   flex: 1,
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   justifyContent: 'space-around',
  //   backgroundColor: '#00000040',
  // },
  // activityIndicatorWrapper: {
  //   backgroundColor: '#fff',
  //   height: 100,
  //   width: 100,
  //   borderRadius: 10,
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-around',
  // },
  activityIndicator: {
    position:'relative',
    alignItems: 'center',
  },
});